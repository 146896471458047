import {DOMAIN_URL} from "../constants/constants";
import axios from "axios";


export const getFaqList = async () => {
  const res = await axios.get(DOMAIN_URL + '/faqs',
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = res.data.map(item => {
    return {
      id: item._id,
      title: item.title,
      text: item.text,
      list: item.list
    }
  });

  return data || [];
};
