import React from "react";

import Title from "../../components/Title";

import {POST} from "../../constants/constants";

import MediumWidget from "../../components/MediumWidget";

export default function PostList() {

  return (
    <section className="cf-section--gray">
      <div className="cf-section__container">
        <Title sectionSize text={POST.title}/>
        <p className="cf-center">
          {POST.description}&nbsp;
          <a href={POST.btn.link}
             className="cf-link--primary"
             target='_blank'
             rel="noopener noreferrer">
            <span>{POST.btn.txt}</span>
          </a>
        </p>
        {/* <MediumWidget/> */}
      </div>
    </section>
  )
}

