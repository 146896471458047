import React, { useState } from "react";

import "../styles/components/hero/hero.scss";


import { Icon } from "@material-ui/core";
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
//import SwipeableViews from 'react-swipeable-views'; //add swiping feature -npm install react-swipeable-views

import "../styles/components/hero/hero.scss";
import '../styles/components/carousel.scss'
import "../styles/components/buttons/btn-white-o.scss";


import { HOME_PAGE } from "../constants/constants";


const ImageSlider = ({ slides, style, hero }) => {
    const [current, setCurrent] = useState(0)
    const length = slides.length

    const nextSlide = () => {
        setCurrent(current === length - 1 ? 0 : current + 1);
    }

    const prevSlide = () => {
        setCurrent(current === 0 ? length - 1 : current - 1);
    }

    if (!Array.isArray(slides) || slides.length <= 0) {
        return null;
    }

    const dotIndicator = index => {
        setCurrent(index)
    }

    return (
        <section className="slider">
            <ChevronLeftIcon className='left-arrow' onClick={prevSlide} />
            <ChevronRightIcon className='right-arrow' onClick={nextSlide} />

            {/*This is to change the format of each slide.*/}
            {HOME_PAGE.map((slide, index) => {
                return (

                    <div
                        className={index === current ? 'slide active' : 'slide'}
                        key={index}
                    >

                        {index === current && (
                            <>

                                {/*Where the text is grouped */}
                                {slide.title != null &&

                                    <header style={style} className={`cf-hero ${slide.image}`} >
                                        <div className="cf-hero__info text-container" >
                                            <h1 className=" " >
                                                {slide.title}
                                            </h1>
                                            <p className="cf-hero__gap">
                                                {slide.info}
                                            </p>
                                            {/*
                                            <a className="cf-hero__link"
                                                href={slide.note.link}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                target="_blank">{slide.note.text}</a>
                                            */}

                                        </div>

                                        <div className="cf-hero__btn-group" >
                                            {slide.buttons.map((item, index) => {
                                                return (
                                                    <a href={item.link}
                                                        key={index}
                                                        target='_blank'
                                                        rel="noopener noreferrer"
                                                        className='cf-btn--white-o'
                                                        id="">
                                                        <span>{item.text}</span>
                                                    </a>
                                                )
                                            })}
                                        </div>
                                        <div className="dotsbox" style={{ textAlign: " center" }}>

                                            {Array.from({ length: 3 }).map((item, index) => (
                                                <span
                                                    className={current === index ? 'dot active' : 'dot'}
                                                    key={0}
                                                    onClick={() => dotIndicator(index)}></span>
                                            ))}

                                            {/*<span className={index === 1 ? 'dot active' : 'dot'} key={1} onClick={() => dotIndicator(index)}></span>*/}
                                            {/*<span className={index === 2 ? 'dot active' : 'dot'} key={2} onClick={() => dotIndicator(index)}></span>*/}
                                        </div>
                                    </header>
                                }
                            </>
                        )}

                    </div>

                );
            })}


        </section>
    );
};

{/* add swiping functionality later
const SlideSwiper = () => (
    <SwipeableViews>
    </SwipeableViews>    
)
*/}




export default ImageSlider;
