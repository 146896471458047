import React, { Component, useEffect, useState } from "react";
import Title from "./Title";

import "../styles/components/gridcards.scss";
import { options } from "mongoose";

export default class GridCards extends Component {
  state = {
    resources: [
      {
        id: 1,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "Apple Developer Account",
        website: "",
        paragraph:
          "Developers will have their own organization Apple developer account to publish their application on the Apple App Store.",
        link: "https://apps.apple.com/us/developer/comida-for-familias/id1542680065",
      },

      {
        id: 2,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "User Testing",
        website: "usertesting.com",
        paragraph:
          "Our UX researchers use UserTesting for their user research studies to optimize apps and websites.",
        link: "https://www.usertesting.com/resources/customers/comida-familias",
      },

      {
        id: 3,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "G-Suite",
        website: "google.com",
        paragraph:
          "Get your own organization G-suite account which gives you access to Google Ads, Firebase, Docs, Spreadsheets, and more.",
        link: "https://www.google.com/grants/",
      },
      {
        id: 4,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "Medium",
        website: "medium.com",
        paragraph:
          "Establish credibility and gain a following by publishing reports, studies, and tutorials on Medium.",
        link: "https://medium.com/comidaforfamilias/redesigning-comida-for-familias-incs-official-website-15393fd4d42c",
      },
      {
        id: 5,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "Comida For Familias Blog",
        website: "comidaforfamilias.blog",
        paragraph:
          "Did you know we have our own publication website? See what other volunteers have published on our blog website.",
        link: "https://www.usertesting.com/resources/customers/comida-familias",
      },
      {
        id: 6,
        img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
        title: "Youtube",
        website: "youtube.com",
        paragraph: `Create videos in the name of the organization and share your expertise and establish credibility.
        Videos will be reviewed before publishing to look professional.`,
        link: "https://www.youtube.com/channel/UCmDrvxZ2ftlh8w7qBnNWhYA/?sub_confirmation=1",
      },
    ],
  };

  render() {
    //--------USE TO DO IT THE "RIGHT WAY" in JS------------
    // https://dev.to/producthackers/intersection-observer-using-react-49ko
    // const containerRef = useRef(null);
    // const [show, hidden] = useState(false);

    // const callbackFunction = (entries) => {
    //   const [entry] = entries;
    //   show(entry.isIntersectin);
    //   const options = {
    //     root: null,
    //     rootMargin: "0px",
    //     treshold: 0.5,
    //   };
    // };

    // useEffect(()=> {
    //   const observer = new IntersectionObserver(callbackFunction, options)
    //   if(containerRef.current) observer.observe(containerRef.current)

    //   return() => {
    //     if(containerRef.current) observer.unobserve(containerRef.current)
    //   }
    // }, [containerRef,options])

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          console.log(entry);
          if (entry.isIntersecting) {
            entry.target.classList.add("show");
          } else {
            entry.target.classList.remove("show");
          }
        });
      },
      { rootMargin: "50px" }
    );

    const hiddenElements = document.querySelectorAll(".cf-grid__col");
    hiddenElements.forEach((el) => observer.observe(el));

    return (
      <div className="cf-grid cf-grid__cards">
        {this.state.resources.map((item, index) => {
          return (
            <ul
              className="cf-grid__col hidden show"
              style={{ padding: "0" }}
              key={index}
            >
              <li>
                <div className={`cf-box cf-img-${item.id}`}>
                  <a
                    href={item.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="theme-bg wow fadeInLeft animated"
                    data-wow-delay="0.2s"
                    // style={{
                    //   visibility: "visible",
                    //   animationDelay: "0.2s",
                    //   animationName: "fadeInLeft;",
                    // }}
                  >
                    <div className="cf-info">
                      <h3>{item.title}</h3>
                      <p>{item.paragraph}</p>
                    </div>
                  </a>
                </div>
              </li>
            </ul>
          );
        })}
      </div>
    );
  }
}
