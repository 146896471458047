import React from "react";
import {HashLink as Link} from 'react-router-hash-link';

import YoutubeIcon from 'mdi-react/YoutubeIcon';
import LinkedinIcon from 'mdi-react/LinkedinIcon';
import FacebookIcon from "mdi-react/FacebookIcon";
import InstagramIcon from "mdi-react/InstagramIcon";
import TwitterIcon from "mdi-react/TwitterIcon";

import {CONTACTS_CONTENT, DONORS} from "../constants/constants";

import "../styles/layout/footer.scss";

import taxExempt from "./../images/tax-exempt.jpeg";

const Footer = () => (
  <footer className="cf-footer">
    <div className="cf-footer__container">
      <div className="cf-footer__info">
        <address className="cf-col">
          <ul className="cf-footer__list">
            <li className="cf-footer__title">contact us</li>
            <li>{CONTACTS_CONTENT.address}</li>
            <li><strong>phone:</strong>&nbsp;
              <a href={`tel:${CONTACTS_CONTENT.phone}`}>{CONTACTS_CONTENT.phone}</a>
            </li>
            <li><strong>email:</strong>&nbsp;
              <a href={`mailto:${CONTACTS_CONTENT.email}`}>{CONTACTS_CONTENT.email}</a>
            </li>
          </ul>
        </address>

        <ul className="cf-col cf-footer__list cf-uppercase">
          <li className="cf-footer__title">about us</li>
          <li><Link to="/#exemptionPurposes">exemption purposes</Link></li>
          <li><a href={taxExempt}
                 rel="noopener noreferrer"
                 target="_blank">exemption letter</a></li>
          <li>
            <a href="https://www.youtube.com/embed/z331jo3kHXs"
               rel="noopener noreferrer"
               target="_blank">mission statement</a>
          </li>
          <li>
            <a href="https://play.google.com/store/apps/dev?id=7141045620730304740"
               rel="noopener noreferrer"
               target="_blank">Android Apps</a>
          </li>
          <li>
            <a href="https://apps.apple.com/us/developer/comida-for-familias/id1542680065"
               rel="noopener noreferrer"
               target="_blank">Apple Apps</a>
          </li>
        </ul>

        <div className="cf-col cf-footer__socials">
          {CONTACTS_CONTENT.socials.map((link, index) => {
            return (
              <a href={link.href}
                 key={index}
                 aria-label={`To open ${link.ariaLabel}`}
                 rel="noopener noreferrer"
                 target="_blank">
                {link.ico === "facebook" && <FacebookIcon/>}
                {link.ico === "instagram" && <InstagramIcon/>}
                {link.ico === "linkedin" && <LinkedinIcon/>}
                {link.ico === "twitter" && <TwitterIcon/>}
                {link.ico === "youtube" && <YoutubeIcon/>}
              </a>
            )
          })}
        </div>
      </div>
      <div className="cf-footer__donors">
        <div className="cf-footer__title">{DONORS.title}</div>
        {DONORS.list.map((item, idx) => {
          return (
            <a href={item.link}
               key={idx}
               rel="noopener noreferrer"
               target='_blank'>{item.name}</a>
          )
        })}
      </div>
      <div className="cf-footer__copyright">{CONTACTS_CONTENT.copyright}</div>
    </div>
  </footer>
)

export default Footer;
