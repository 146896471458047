import React, {Component} from 'react';

import Title from "./Title";
import GridCards from './GridCards';

import SchoolOutlineIcon from 'mdi-react/SchoolOutlineIcon';
import LibraryIcon from "mdi-react/LibraryIcon";
import CharityIcon from "mdi-react/CharityIcon";
import ClockOutline from 'mdi-react/ClockOutlineIcon';
import AccountGroup from 'mdi-react/AccountGroupIcon';

import "../styles/layout/section.scss";
import "../styles/components/grid.scss";

export default class VolunteerReasons extends Component {
  state = {
    services: [
      {
        icon: <LibraryIcon/>,
        title: "To Learn",
        // info: "We provide guidance to our volunteers on how to successfully accomplish their activities. You may also collaborate with others within your field and learn from them. "
      },

      {
        icon: <ClockOutline/>,
        title: "Volunteer Hours",
        info: ""
      },

      {
        icon: <AccountGroup/>,
        title: "Community",
        // info: "Charitable activities we do follow codes T50 (Philanthropy, Charity, and Volunteerism Promotion) and U12 (Fundraising and Fund Distribution)."
      }

    ]
  };

  render() {
    return (
      <section className="cf-section" style={{paddingBottom: "0px"}}>
        <div className="cf-section__container" style={{paddingBottom: "0px"}}>
          <Title sectionSize text="Some Reasons Why People Volunteer"/>

          <div className="cf-grid">
            {this.state.services.map((item, index) => {
              return (
                <ul className="cf-grid__col"
                    key={index}>
                  <li className="cf-grid__ico">{item.icon}</li>
                  <li className="cf-grid__title">{item.title}</li>
                  <li>{item.info}</li>
                </ul>
              )
            })}
          </div>
        </div>
      </section>
    );
  }
}
