import React, { Component } from 'react';

import Title from "./Title";

import SchoolOutlineIcon from 'mdi-react/SchoolOutlineIcon';
import LibraryIcon from "mdi-react/LibraryIcon";
import CharityIcon from "mdi-react/CharityIcon";

import "../styles/layout/section.scss";
import "../styles/components/grid.scss";

export default class Impact extends Component {
    state = {
        services: [
            {
                logo: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1619074976/comidaforfamilias-site/home/CFF_logo_200_kc3ppp.png",
                alt: "Comida For Familias, Inc.",
                tooltip: "You are already on our main site!",
                link: "https://comidaforfamilias.com/about"
            },

            {
                logo: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1651011679/comidaforfamilias-site/home/code_for_familias_text.png",
                alt: "Code For Familias",
                tooltip: "Click to publish an application in 3 months.",
                link: "https://codeforfamilias.com"
            },

            {
                logo: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1651011679/comidaforfamilias-site/home/design_for_familias_text.png",
                alt:"Design For Familias",
                tooltip: "Site Coming Soon! ",
                link: ""
            }
            /*
            {
                logo: "https://mediadonor.com/img/logo1.png",
                alt: "Media Donor",
                link: "https://mediadonor.com"
            },
            
            {
                logo: "",
                alt: "Culinary Contributors",
                link: "https://culinarycontributors.com"
            },
            {
                logo: "",
                alt: "",
                link: "https://culinarycontributors.com"
            }
            */
        ]
    };

    render() {
        return (
            <section className="cf-section">
                <div className="cf-section__container">
                    <Title sectionSize text="Brands" />
                    <div className="cf-grid">
                        {this.state.services.map((item, index) => {
                            return (
                                <ul className="cf-grid__col"
                                    key={index}>
                                    <a href={item.link}>
                                        <img src={item.logo} alt={item.alt} className="cf-grid__logo" 
                                        data-bs-toggle="tooltip" 
                                        data-bs-placement="top" 
                                        title={item.tooltip} />
                                    </a>
                                </ul>
                            )
                        })}
                    </div>
                </div>
            </section>
        );
    }
}
