import {DOMAIN_URL} from "../constants/constants";
import axios from "axios";


const checkDate = (dateString) => {
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    hour: 'numeric', minute: 'numeric', second: 'numeric',
    day: "2-digit"
  }).format(date);
}

const TYPE = 'News';

export const getNews = async () => {
  const res = await axios.get(DOMAIN_URL +'/news',
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = res.data.reverse().map(item => {
    return {
      id: item._id,
      theme: item.theme,
      title: item.title,
      date: checkDate(item.createDate),
      imgPreview: item.imgPreview,
      link: TYPE,
      buttonLink: item.buttonLink,
      buttonText: item.buttonText,
      descriptionLimit: 200,
      description: item.description
    }
  });

  return data || [];
};


export const getNewsById = async (newsId) => {
  const res = await axios.get(`/news/${newsId}`,
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = {
    id: res.data._id,
    theme: res.data.theme,
    title: res.data.title,
    date: checkDate(res.data.createDate),
    img: res.data.img,
    buttonLink: res.data.buttonLink,
    buttonText: res.data.buttonText,
    descriptionLimit: 200,
    description: res.data.description
  }

  return data || {};
};
