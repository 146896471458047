import React, {Component} from "react";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Breadcrumbs from "../../components/Breadcrumbs";
import ListStyled from "../../components/ListStyled";

import ChevronDownIcon from "mdi-react/ChevronDownIcon";

import {GET_INVOLVED_INTERNS} from "../../constants/constants"

import "../../styles/layout/section.scss"
import "../../styles/components/card/card-preview.scss"
import "../../styles/components/card/card-list.scss"
import "../../styles/components/breadcrumbs.scss"
import "../../styles/pages/opportunity.scss"

import {getInternships} from "../../services/internshipsService";
import Loading from "../../components/Loading";
import {INTERNSHIPS} from "../../constants/constants"

class Positions extends Component {

  constructor(props) {
    super(props);

    const isOpenDescription = {};

    this.state = {
      data: [],
      setLoading: {
        loadingStatus: true
      },
      isOpenDescription
    }
  }

  componentDidMount() {
    getInternships()
      .then((res) => {
        this.setState({
          data: res,
          setLoading: {loadingStatus: false}
        });
      })
      .catch((e) => console.log(e));
  }
  
  /**
   * Toggle Dropdown by click.
   * @param index - of dropdown.
   */
  toggleDescription = (index) => {
    const {
      state: {isOpenDescription},
    } = this;
    const isOpen = !!isOpenDescription[index];
    this.setState({isOpenDescription: {[index]: !isOpen}});
  }



  render() {
    const roleListContent = (card, index) => {
      if (this.state.isOpenDescription[index]) {
        return (
          <ListStyled data={card.role}/>
        )
      } else {
        return null
      }
    }

    const content = () => {
      return (
        <div className="cf-card-list">
          {this.state.data.map((card, index) => {
            const isOpenClass = this.state.isOpenDescription[index] ? 'is-open' : '';

            return (
              <div className="cf-card-preview"
                   key={index}>
                <div className="cf-card-preview__body">
                  <div className="cf-card-preview__info">
                    <h3 className="cf-card-preview__title">{card.title}</h3>
                    <p>{card.description}</p>
                    <strong className={`cf-opp__subtitle ${isOpenClass}`}
                            onClick={() => this.toggleDescription(index)}>
                      What you can expect
                      <ChevronDownIcon className="cf-opp__ico"/>
                    </strong>
                    {roleListContent(card, index)}
                  </div>
                  <div className="cf-card-preview__actions">
                    <a href={card.applyLink}
                       className="cf-btn--primary-o"
                       target="_target"
                       rel="noopener noreferrer"
                       onClick={window.gtag_rgtag_report_volunteer_registration}>
                      <span>Register</span>
                    </a>
                    {/*TODO: Sprint 2 <ShareIcons/>*/}
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <React.Fragment>
        <Hero hero="cf-bg--internships">
          <Banner title={INTERNSHIPS.title}
                  subtitle={INTERNSHIPS.subtitle}/>
                  *The registration fee is waived for interns that are apply and are accepted through Handshake.*
                  <br></br>
                  <p>Below are brief descriptions of the roles available. Clicking on the button will take you to the handshake app/webpage.</p>
        </Hero>
        <section className="cf-section cf-opp">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={GET_INVOLVED_INTERNS.breadcrumbs.opportunity}/>

            {this.state.setLoading.loadingStatus ? (
              <Loading/>
            ) : content()}
          </div>
        </section>
      </React.Fragment>
    );
  }
};

export default Positions;