import React from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import Banner from "../../components/Banner";
import Hero from "../../components/Hero";
import Badge from "../../components/Badge";
import DateCard from "../../components/DateCard";

import {NEWS_EVENTS_SWITCH_TECH, NEWS_EVENTS_SWITCH_TOP, NEWS_TYPE} from "../../constants/constants";

import {getNewsById} from "../../services/newsService";

import noImg from "../../images/home_bg.jpg";

class NewsDetail extends React.Component{
  constructor(props) {
    super(props);

    this.state = {
      card: {},
      itemId: props.match.params.newsId
    };
  }

  componentDidMount() {
    getNewsById(this.state.itemId)
      .then((res) => {
        this.setState({
          card: res
        });
      })
      .catch((e) => console.log(e))
  }

 render() {
   const checkPhoto = () => {
     return {backgroundImage: this.state.card.img ? `url(${this.state.card.img})` : `url(${noImg})`}
   }

   const breadcrumbsContent = [
     {name: NEWS_TYPE, link: `/NewsEvents/${NEWS_TYPE}`}
   ]

   return (
     <React.Fragment>
       <Hero style={checkPhoto()}
             hero="cf-bg--detail">
         <Banner title={this.state.card.title}/>
       </Hero>
       <section className="cf-section">
         <div className="cf-section__container cf-padding-t-base">
           <Breadcrumbs data={breadcrumbsContent}/>
           <div className="cf-card-preview">
             <div className="cf-card-preview__body">
               <div className="cf-card-preview__info">
                 <Badge text={this.state.card.theme}
                        badgeType={this.state.card.theme === NEWS_EVENTS_SWITCH_TOP ? "primary" : this.state.card.theme === NEWS_EVENTS_SWITCH_TECH ? "secondary" : null}
                        classBadge="cf-card-preview__badge"/>
                 <DateCard text={this.state.card.date}/>
                 <p>{this.state.card.description}</p>
               </div>
               {this.state.card.buttonLink && (
                   <div className="cf-card-preview__actions">
                     <a href={this.state.card.buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="cf-btn--primary-o">
                       <span>{this.state.card.buttonText}</span>
                     </a>
                   </div>
                 )}
             </div>
           </div>
         </div>
       </section>
     </React.Fragment>
   );
 }
};

export default NewsDetail;
