import React from "react";
import {Link} from "react-router-dom";

import "../styles/components/breadcrumbs.scss"

class Breadcrumbs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data
    };
  }

  render() {
    return (
      <div className="cf-breadcrumbs">
        {this.state.data.map((item, index) => {
          return (
            <React.Fragment key={index}>
              {item.link ? (
                <Link className="cf-breadcrumbs__link"
                      to={item.link}><span>{item.name}</span></Link>
              ) : (
                <span className="cf-breadcrumbs__current">{item.name}</span>
              )}
            </React.Fragment>
          )
        })}
      </div>
    );
  }
}

export default Breadcrumbs;
