import React, { Component } from "react";
import Modal from "./Modal";

import { F1OPT_PAGE } from "../constants/constants";

import "../styles/components/pricing.scss";
import "../styles/components/modal.scss";
//import { Modal } from '@mui/material';

export default class Pricing extends Component {
    constructor() {
        super()
        this.state = {
            setLoading: {
                loadingStatus: true
            },
            cards: F1OPT_PAGE.priceCards,
        }
        this.showModalFunction = this.showModalFunction.bind(this)
    }

    // componentDidMount() {
    //     getProjects()
    //         .then((res) => {
    //             this.setState({
    //                 data: res,
    //                 setLoading: { loadingStatus: false }
    //             });
    //         })
    //         .catch((e) => console.log(e))
    // }

    showModalFunction(id) {
        this.setState(e => {
            const visibilityUpdate = e.cards.map(card => {
                if (card.id === id) {
                    card.showModal = !card.showModal
                }
                return card
            })
            return {
                cards: visibilityUpdate
            }
        })
    }

    render() {
        return (
            <div className="container overflow-hidden sm-space">
                <div className="row row-cols-1 row-cols-md-3 mb-3 text-center">
                    {F1OPT_PAGE.priceCards.map((item, index) => {
                        return (
                            <div className="col mb-3" key={item.id}>
                                <div className="card shadow-sm">
                                    <div className="card-header">{item.header}</div>
                                </div>
                                <div className="card-body">
                                    <h1 className="card-title pricing card-title">
                                        ${item.price}<small className="text-muted">{item.per}</small>
                                    </h1>
                                    <ul className="list-unstyled mt-3 mb-4">
                                        {item.details.map((itemDetails, index) => {
                                            return (
                                                <li key={itemDetails.id}>{itemDetails.text}</li>
                                            )
                                        })}
                                    </ul>
                                    <a className="cf-btn--primary-o" onClick={() => this.showModalFunction(item.id)}>
                                        Schedule a Call
                                    </a>

                                    {item.showModal === true ?
                                        (console.log(item.showModal),
                                            <Modal
                                                key={item.id}
                                                item={item}
                                                onClick={this.showModalFunction}
                                            />) : null}
                                </div>
                            </div>
                        )
                    })}

                </div>
            </div>
        );
    }
}