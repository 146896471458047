import React from "react";

import ContentContainer from "../../components/ContentContainer"
import Title from "../../components/Title";

import "../../styles/layout/section.scss";

export default function Current() {
  return (
    <section className="cf-section"
             id="exemptionPurposes">
      <div className="cf-section__container">
        <Title sectionSize text="Check us out!"/>
        <ContentContainer/>
      </div>
    </section>
  )
}
