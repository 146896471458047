import React from 'react';

import "../styles/components/title.scss";

export default function Title({text, primaryText, bigSize, sectionSize, alignLeft}) {
  let titleClass;

  if(bigSize) {
    titleClass = 'cf-title-primary cf-size-big'
  } else if (sectionSize) {
    titleClass = 'cf-title-primary cf-size-section'
  } else {
    titleClass = 'cf-title-primary'
  }

  return (
    <h3 className={alignLeft ? `${titleClass} cf-align-left` : titleClass}>{text} <span>{primaryText}</span></h3>
  );
}
