import React from 'react';
import Hero from '../../components/Hero';
import Banner from "../../components/Banner";
import Pricing from "../../components/Pricing";

import {F1OPT_PAGE} from "../../constants/constants";

export default function F1OPT(){
    return(
        <React.Fragment>
            <Hero>
                <Banner title={F1OPT_PAGE.title}
                    subtitle={F1OPT_PAGE.description}>
                        <p>
                            For those who are not on a 17-month extension, and where this practice does not violate any labor laws, this employment may include those listed 
                            in the 
                            <a href="https://www.nafsa.org/professional-resources/browse-by-interest/sevp-opt-policy-guidance-1004-03" 
                            target="_blank" 
                            rel="noopener noreferrer"> SEVP Policy Guidance 1004-03</a>
                        </p>
                </Banner>
                <Pricing></Pricing>
            </Hero>
        </React.Fragment>
    )

}