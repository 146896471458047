import React from "react";
import {Link} from "react-router-dom";

import "../styles/components/list.scss"

export default function ListStyled({data}) {
  return (
    <ul className="cf-list">
      {data.map((item, index) => {
        return (
          <li className="cf-list__item"
              key={index}>
            <span className="cf-list__circle">&nbsp;</span>
            {item.link && (
              <Link to={item.link}><span>{item.text}</span></Link>
            )}
            {!item.link && <span>{item.text}</span>}
          </li>
        )
      })}
    </ul>
  );
}
