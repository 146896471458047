import React, {Component} from 'react';
import {Link} from "react-router-dom";

import {GIVING_BACK} from "../constants/constants"

import "../styles/components/date.scss"
import "../styles/components/card/card-preview.scss"
import "../styles/components/card/card-list.scss"
import "../styles/components/buttons/btn-primary-o.scss"

export default class Impact extends Component {

  render() {
    return (
      <div className="cf-card-list">
        {GIVING_BACK.map((item, index) => {

          const date = () => {
            if (item.date) {
              return (
                <div className="cf-date">Updated&nbsp;&mdash;&nbsp;{item.date}</div>
              );
            }
            return null;
          };

          return (
            <div className="cf-card-preview"
                 key={index}>
              <div className="cf-card-preview__img">

                {<iframe width="560"
                        height="315"
                        title='Go to youtube'
                        src={item.videoLink}
                        alt={item.alt}
                        frameBorder="0"
                        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen/>}
              </div>
              <div className="cf-card-preview__body">
                <div className="cf-card-preview__info">
                  <h4 className="cf-card-preview__title">{item.title}</h4>
                  {date()}
                  <p>{item.info}</p>
                </div>
                <div className="cf-card-preview__actions">
                  <Link to={item.linkpage}
                        className="cf-btn--primary-o">
                    <span>Learn More</span>
                  </Link>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
      ;
  }
}
