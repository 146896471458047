import React from "react";

import {
  EVENT_TYPE,
  NEWS_EVENTS, NEWS_EVENTS_SWITCH_CURRENT,
  NEWS_EVENTS_SWITCH_PAST,
  NEWS_EVENTS_SWITCH_UPCOMING,
} from "../../constants/constants";

import {getEvents} from "../../services/eventsService";
import Loading from "../../components/Loading";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Breadcrumbs from "../../components/Breadcrumbs";
import Switcher from "../../components/Switcher";
import NoData from "../../components/NoData";
import NewsEventsCard from "./NewsEventsCard";

class Events extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      setLoading: {
        loadingStatus: true
      },
      title: EVENT_TYPE,
      switcher: NEWS_EVENTS.switcherList.events,
      breadcrumbs: NEWS_EVENTS.breadcrumbs.events,
      filterBy: NEWS_EVENTS.switcherList.events[0].name
    };
  }

  componentDidMount() {
    getEvents()
      .then((res) => {
        const data = res.sort((a, b) => b.startDate - a.startDate)
        this.setState({
          data,
          _data: data,
          setLoading: {loadingStatus: false}
        });
      })
      .catch((e) => console.log(e))
  }

  /**
   * Check - has Event list or empty.
   * @returns {*}
   */
  content = () => {
    const list = this.state.data.map((item, index) =>
      <NewsEventsCard key={index}
                      badgeText={item.location}
                      badgeColor="secondary"
                      item={item}/>
    )

    if (this.state.data.length > 0) {
      if (this.state.setLoading.loadingStatus) {
        return <Loading/>
      } else {
        return list
      }
    } else {
      return <NoData text={`There is no ${this.state.title} yet...`}/>
    }
  }

  /**
   * Update this.state.filterBy - with selected value for filter list of Events.
   * @param {string} value - of selected Tab from Switcher Component.
   */
  updateData = (value) => {
    // To highlight tab.
    this.setState({filterBy: value})

    // To filer data by Date.
    this.filterListByDate(value);
  }

  /**
   * Filter the list of  Events by date.
   * @param {string} value - of selected Tab from Switcher Component.
   */
  filterListByDate = (value) => {
    let data, listUpcoming, listPast, listCurrent;
    const now = Date.now();

    const dataInitial = this.state._data;
    listUpcoming = dataInitial.filter(item => new Date(item.filterStartDate) > now);
    listPast = dataInitial.filter(item => new Date(item.filterEndDate) < now);
    listCurrent = dataInitial.filter(item =>  new Date(item.filterStartDate) <= now && new Date(item.filterEndDate) >= now);

    switch (value) {
      case NEWS_EVENTS_SWITCH_UPCOMING:
        data = listUpcoming;
        break;
      case NEWS_EVENTS_SWITCH_PAST:
        data = listPast;
        break;
      case NEWS_EVENTS_SWITCH_CURRENT:
        data = listCurrent;
        break;
      default:
        data = dataInitial;
    }

    this.setState({data});
  }

  render() {
    return (
      <React.Fragment>
        <Hero hero="cf-bg--events">
          <Banner title={this.state.title}/>
        </Hero>
        <section className="cf-section">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={this.state.breadcrumbs}/>
            <Switcher data={this.state.switcher}
                      defaultValue={this.state.filterBy}
                      updateData={this.updateData}/>
            <div className="cf-card-list cf-card-preview--small">
              {this.content()}
            </div>
          </div>
        </section>
      </React.Fragment>
    )
  }
};

export default Events;
