import React, {Component} from 'react';

import Title from "./Title";

import SchoolOutlineIcon from 'mdi-react/SchoolOutlineIcon';
import LibraryIcon from "mdi-react/LibraryIcon";
import CharityIcon from "mdi-react/CharityIcon";

import "../styles/layout/section.scss";
import "../styles/components/grid.scss";

export default class Impact extends Component {
  state = {
    services: [
      {
        icon: <LibraryIcon/>,
        title: "60% - Scientific Impact",
        info: "Our primary purpose is dedicated to scientific activities. The activity codes that we focus on are U40 (Engineering & Technology) and U41 (Computer Science)."
      },

      {
        icon: <SchoolOutlineIcon/>,
        title: "25% - Educational Impact",
        info: "The activities we focus on for our educational purpose are codes K40 (Nutrition), B80 (Student Services), and J22 (Job Training)."
      },

      {
        icon: <CharityIcon/>,
        title: "15% - Charitable Impact",
        info: "Charitable activities we do follow codes T50 (Philanthropy, Charity, and Volunteerism Promotion) and U12 (Fundraising and Fund Distribution)."
      }

    ]
  };

  render() {
    return (
      <section className="cf-section">
        <div className="cf-section__container">
          <Title sectionSize text="Our Exempt Purposes"/>
          
          <div className="cf-grid">
            {this.state.services.map((item, index) => {
              return (
                <ul className="cf-grid__col"
                    key={index}>
                  <li className="cf-grid__ico">{item.icon}</li>
                  <li className="cf-grid__title">{item.title}</li>
                  <li>{item.info}</li>
                </ul>
              )
            })}
          </div>
        </div>
      </section>
    );
  }
}
