import { DOMAIN_URL } from "../constants/constants";
import axios from "axios";

export const getTeam = async () => {
  const res = await axios.get(DOMAIN_URL + "/volunteers", {
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  });
  const data = res.data.map((item) => {
    return {
      id: item._id,
      name: item.volunteerName,
      img: item.volunteerPicture,
      description: item.volunteerDescription,
      socials: [
        { name: "linkedin", link: item.volunteerLinkedIn },
        { name: "facebook", link: item.volunteerFacebook },
      ],
      status: item.volunteerStatus,
      position: item.volunteerPosition,
      visible: item.volunteerVisible,
    };
  });
  if (data) {
    return data;
  }
  return [];
};
