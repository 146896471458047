import React from "react";

import "../../styles/components/inputs/input.scss";

export const TextInput = ({ handler, touched, hasError, meta }) => (
  <div className='cf-form__row'>
    <div className={touched && hasError("required") && meta.message ? `cf-input is-invalid` : `cf-input`}>
      <input type="text"
        //for={`${meta.for}`}
        id={`${meta.id}`}
        //maxlength={`${meta.length}`}
        name={`${meta.name}`}

        placeholder={`${meta.label}`} {...handler()} />
      <div className="cf-form__hint">
        {touched
          && hasError("required")
          && `${meta.message} is required`}
      </div>
    </div>
  </div>
)

