import React, {Component} from "react";

import '../styles/override/medium-widget.scss'

class MediumWidget extends Component {
  componentDidMount() {
    try {

      const widget = document.getElementById("medium-widget");
      if (!!widget) {
        window.mediumWidget();
      }
    } catch (e) {
      window.location.reload();
    }
  }

  render() {
    return (<div id="medium-widget" ></div>)
  }
}

export default MediumWidget;
