import React from "react";

import "../styles/components/bage.scss"

export default function Badge({text, badgeType, classBadge}) {
  const secondaryClass = 'cf-badge__secondary'
  const primaryClass = 'cf-badge__primary'

  return (
    <div className={`cf-badge ${classBadge} ${badgeType === 'secondary' ? secondaryClass : ''} ${badgeType === 'primary' ? primaryClass : ''}`}>
      {text}
    </div>
  );
}
