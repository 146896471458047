import React from "react";

import "../styles/components/bage.scss"

export default function DateCard({text, classDate}) {
  return (
    <div className={`cf-date ${classDate}`}>
      {text}
    </div>
  );
}
