import React from "react";
import Hero from "../components/Hero";
import Title from "../components/Title";
import Timeline from "./Timeline";
import Banner from "../components/Banner";

export default function About() {

    return (
        <div>
            <Hero hero="cf-bg--about">
                <Banner title="About Us"
                    subtitle="" />
                <p>Comida For Familias, Inc. was founded in 2019 by Antonio Mendieta. Antonio was inspired
                    by a group of driven executives who were creating applications for their own nonprofit organizations at a 
                    hackathon in San Francisco. After the event, he decided to use his skills in computer science to build charitable apps 
                    and services for public benefit. 
                    This led him to create Comida For Familias (CFFI), a 501(c)(3) scientific research organization.
                    <br/><br/>
                    Since its founding, CFFI has created applications, websites, videos, 
                    groups, and events to accomplish scientific, educational, and charitable purposes for the public. 
                    The organization has also created its 
                    own brands, to hone in on each volunteer's
                    specific skills and interests.
                    <br /><br />
                    Our vision for the organization is to innovate the way people share and provide food and resources with one another.
                    So go on and take a look at what Comida For Familias has accomplished. We look forward to having you be part of our familia!
                </p>

            </Hero>
            <Timeline />
        </div>
    )
}