import React from "react";

import Breadcrumbs from "../../components/Breadcrumbs";
import Banner from "../../components/Banner";
import Hero from "../../components/Hero";
import Badge from "../../components/Badge";
import DateCard from "../../components/DateCard";

import {EVENT_TYPE} from "../../constants/constants";

import {getEventById} from "../../services/eventsService";

import noImg from "../../images/home_bg.jpg";

class EventDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      card: {},
      itemId: props.match.params.eventId
    };
  }

  componentDidMount() {
    getEventById(this.state.itemId)
      .then((res) => {
        this.setState({
          card: res
        });
      })
      .catch((e) => console.log(e))
  }

  render() {
    const checkPhoto = () => {
      return {backgroundImage: this.state.card.img ? `url(${this.state.card.img})` : `url(${noImg})`}
    }

    const breadcrumbsContent = [
      {name: EVENT_TYPE, link: `/NewsEvents/${EVENT_TYPE}`}
    ]

    const buttonRegister = () => {
      const now = Date.now();
      const past = this.state.card.endDate < now;

      const content = () => {
        if (past) {
          return 'Registration is over'
        } else {
          return 'Register now'
        }
      }

      return (
        <a href={this.state.card.buttonLink}
           target="_blank"
           rel="noopener noreferrer"
           className={`cf-btn--primary-o ${past ? 'is-disabled' : ''}`}>
          <span>{content()}</span>
        </a>
      )
    }

    return (
      <React.Fragment>
        <Hero style={checkPhoto()}
              hero="cf-bg--detail">
          <Banner title={this.state.card.title}/>
        </Hero>
        <section className="cf-section">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={breadcrumbsContent}/>
            <div className="cf-card-preview">
              <div className="cf-card-preview__body">
                <div className="cf-card-preview__info">
                  <Badge text={this.state.card.location}
                         badgeType='secondary'
                         classBadge="cf-card-preview__badge"/>
                  <DateCard text={this.state.card.date}/>
                  <p>{this.state.card.description}</p>
                </div>
                <div className="cf-card-preview__actions">
                  {buttonRegister()}Hello
                  {/*TODO: Sprint 2 <ShareIcons/>*/}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
};

export default EventDetail;
