import React, { Component } from "react";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Title from "../../components/Title";
import Breadcrumbs from "../../components/Breadcrumbs";
import ListStyled from "../../components/ListStyled";

import ChevronDownIcon from "mdi-react/ChevronDownIcon";

import { GET_INVOLVED } from "../../constants/constants";

import "../../styles/layout/section.scss";
import "../../styles/components/card/card-preview.scss";
import "../../styles/components/card/card-list.scss";
import "../../styles/components/breadcrumbs.scss";
import "../../styles/pages/opportunity.scss";

import { getPositions } from "../../services/positionsService";
import Loading from "../../components/Loading";
import { OPPORTUNITIES } from "../../constants/constants";
import {VOLUNTEERGRID} from "../../constants/constants";
import VolunteerReasons from "../../components/VolunteerReasons";
import GridCards from "../../components/GridCards";
import Steps from "./Steps";

class Positions extends Component {
  constructor(props) {
    super(props);

    const isOpenDescription = {};

    this.state = {
      data: [],
      setLoading: {
        loadingStatus: true,
      },
      isOpenDescription,
    };
  }

  componentDidMount() {
    getPositions()
      .then((res) => {
        this.setState({
          data: res,
          setLoading: { loadingStatus: false },
        });
      })
      .catch((e) => console.log(e));
  }

  /**
   * Toggle Dropdown by click.
   * @param index - of dropdown.
   */
  toggleDescription = (index) => {
    const {
      state: { isOpenDescription },
    } = this;
    const isOpen = !!isOpenDescription[index];
    this.setState({ isOpenDescription: { [index]: !isOpen } });
  };

  render() {
    const roleListContent = (card, index) => {
      if (this.state.isOpenDescription[index]) {
        return <ListStyled data={card.role} />;
      } else {
        return null;
      }
    };

    const content = () => {
      return (
        <div className="cf-card-list">
          {this.state.data.map((card, index) => {
            const isOpenClass = this.state.isOpenDescription[index]
              ? "is-open"
              : "";

            return (
              <div className="cf-card-preview" key={index}>
                <div className="cf-card-preview__body">
                  <div className="cf-card-preview__info">
                    <h3 className="cf-card-preview__title">{card.title}</h3>
                    <p>{card.description}</p>
                    <strong
                      className={`cf-opp__subtitle ${isOpenClass}`}
                      onClick={() => this.toggleDescription(index)}
                    >
                      What you can expect
                      <ChevronDownIcon className="cf-opp__ico" />
                    </strong>
                    {roleListContent(card, index)}
                  </div>
                  <div className="cf-card-preview__actions">
                    <a
                      href={card.applyLink}
                      className="cf-btn--primary-o"
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={window.gtag_rgtag_report_volunteer_registration}
                    >
                      <span>Register Now</span>
                    </a>

                    {/*TODO: Sprint 2 <ShareIcons/>*/}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      );
    };

    return (
      <React.Fragment>
        <Hero hero="cf-bg--volunteer">
          <Banner
            title={OPPORTUNITIES.title}
            subtitle={OPPORTUNITIES.subtitle}
          />
        </Hero>

        <VolunteerReasons />

        <section className="cf-section__container">
          <section className="cf-section-promo-secondary">
            <div className="cf-section-promo-secondary__volunteerpic">
              &nbsp;
            </div>
            <div className="cf-section-promo__body">
              <h3 className="cf-title">
                {OPPORTUNITIES.strongaffiliate.title}
              </h3>
              <ul className="cf-section-promo__info">
                <p className="cf-paragraph">
                  {OPPORTUNITIES.strongaffiliate.paragraph}
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cf-btn--primary"
                  href={OPPORTUNITIES.strongaffiliate.link}
                  id={OPPORTUNITIES.strongaffiliate.id}
                >
                  {OPPORTUNITIES.strongaffiliate.buttontext}
                </a>
              </ul>
            </div>
          </section>
        </section>

        <Steps />

        <section className="cf-section__container" style={{ paddingBottom: 0 }}>
          <section className="cf-section-promo-secondary">
            <div className="cf-section-promo-secondary__nontraditional">
              &nbsp;
            </div>
            <div className="cf-section-promo__body">
              <h3 className="cf-title">{OPPORTUNITIES.payment.title}</h3>
              <ul className="cf-section-promo__info">
                <p className="cf-paragraph">
                  {OPPORTUNITIES.payment.paragraph}
                </p>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="cf-btn--primary"
                  href={OPPORTUNITIES.payment.link}
                  id={OPPORTUNITIES.payment.id}
                >
                  {OPPORTUNITIES.payment.buttontext}
                </a>
              </ul>
            </div>
          </section>
        </section>

        {/* GRIDCARDS SECTION END*/}
        <section className="cf-section">
          <div className="cf-section__container">
            <Title sectionSize text="Volunteer Resources" />
            <GridCards gridList={VOLUNTEERGRID}/>
          </div>
        </section>
        {/* GRIDCARDS SECTION END*/}

        <section className="cf-section cf-opp">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={GET_INVOLVED.breadcrumbs.opportunity} />

            {this.state.setLoading.loadingStatus ? <Loading /> : content()}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Positions;
