import React from "react";
import { Route, Switch } from "react-router-dom";

import Home from "./pages/Home/Home";
import Projects from "./pages/Projects";
import Events from "./pages/NewsEvents/Events";
import News from "./pages/NewsEvents/News";
import EventDetail from "./pages/NewsEvents/EventDetail";
import NewsDetail from "./pages/NewsEvents/NewsDetail";
import Positions from "./pages/GetInvolved/Positions";
import FAQ from "./pages/GetInvolved/FAQ";
import TheTeam from "./pages/TheTeam";
import Donate from "./pages/Donate";
import ErrorPage from "./pages/ErrorPage";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import MaaathPP from "./pages/MaaathPP";
import F1OPT from "./pages/GetInvolved/F1OPT";
import Internships from "./pages/GetInvolved/Internships";
import InternsFAQ from "./pages/GetInvolved/InternsFAQ";
import LandingPage from "./pages/LandingPage";
import Quisines from "./pages/Quisines";
import RelaxAssistant from "./pages/RelaxAssistant";
import CeointernPage from "./pages/Ceointern";
import Media from "./pages/Media";
import About from "./pages/About";
//import ScrollToTop from "./components/ScrollToTop";
import HealthyHappyBeings from "./pages/Privacy/HhbPrivacyPolicy";
// import LifeStyle from "./pages/Lifestyle/Lifestyle";

class App extends React.Component {
  componentDidMount = () => {
    this.callBackendAPI()
      .then((res) => console.log(res.express))
      .catch((err) => console.log(err));
  };

  callBackendAPI = async () => {
    const response = await fetch(
      "https://comidaforfamilias.com/express_backend"
    );
    const body = await response.json();

    if (response.status !== 200) {
      throw Error(body.message);
    }
    return body;
  };

  render() {
    return (
      <React.Fragment>
        {/* <ScrollToTop /> */}
        <Navbar />
        <main className="cf-main">
          <div className="cf-main__content">
            <Switch>
              <Route exact path="/" component={Home} />
              <Route
                exact
                path="/projectlist/projectlist"
                component={Projects}
              />
              <Route exact path="/newsevents/events" component={Events} />
              <Route exact path="/newsevents/news" component={News} />
              <Route
                exact
                path="/newsevents/events:eventId"
                component={EventDetail}
              />
              <Route
                exact
                path="/newsevents/news:newsId"
                component={NewsDetail}
              />
              <Route
                exact
                path="/getinvolved/positions/"
                component={Positions}
              />
              <Route
                exact
                path="/getinvolved/internships"
                component={Internships}
              />
              <Route
                exact
                path="/getinvolved/interns_faq"
                component={InternsFAQ}
              />
              <Route exact path="/getinvolved/faq/" component={FAQ} />
              <Route exact path="/theteam/" component={TheTeam} />
              <Route exact path="/about/" component={About} />
              <Route exact path="/donate/" component={Donate} />
              <Route exact path="/f1opt" component={F1OPT} />
              <Route exact path="/landingpage" component={LandingPage} />
              <Route exact path="/Maaath/privacypolicy" component={MaaathPP} />
              <Route
                exact
                path="/mediadonor/"
                render={() => {
                  window.open("https://mediadonor.com/", "_blank");
                }}
              />
              <Route
                exact
                path="/codingqualitycode/"
                render={() => {
                  window.location.href = "codingqualitycode.html";
                }}
              />
              <Route
                exact
                path="/foreigntongue/"
                render={() => {
                  window.location.href = "foreigntongue.html";
                }}
              />
              <Route exact path="/Maaath/privacypolicy" component={MaaathPP} />
              <Route exact path="/quisines" component={Quisines} />
              <Route exact path="/relaxassistant" component={RelaxAssistant} />
              <Route exact path="/media" component={Media} />
              <Route
                exact
                path="/projectlist/appleapps"
                render={() => {
                  window.open(
                    "https://apps.apple.com/us/developer/comida-for-familias/id1542680065",
                    "_blank"
                  );
                }}
              />
              <Route
                exact
                path="/projectlist/androidapps"
                render={() => {
                  window.open(
                    "https://play.google.com/store/apps/dev?id=7141045620730304740",
                    "_blank"
                  );
                }}
              />
              <Route
                exact
                path="/healthyhappybeings/privacypolicy"
                render={HealthyHappyBeings}
              />
              <Route exact path="/ceointern" component={CeointernPage} />
              {/* <Route exact path="/lifestyle" component={LifeStyle}/> */}
              <Route component={ErrorPage} />
            </Switch>
          </div>
          <Footer />
        </main>
      </React.Fragment>
    );
  }
}

export default App;
