import React from "react";
import {
  Validators
} from "react-reactive-form";

import {TextInput} from "./TextInput";
import {Textarea} from "./Textarea";
import { SelectInput } from "./SelectInput";

import "../../styles/components/buttons/btn-primary.scss";
import { Select } from "@material-ui/core";

export const fieldConfig = {
  controls: {
    firstname: {
      options: {
        validators: Validators.required
      },
      render: TextInput,
      meta: {
        label: "First Name",
        message: "First Name",
        for: "first_name",
        id: "first_name",
        name: "first_name"
      }
    },
    lastname: {
      options: {
        validators: Validators.required
      },
      render: TextInput,
      meta: {
        label: "Last Name",
        message: "Last Name",
        for: "last_name",
        id: "last_name",
        name: "last_name"
      }
    },
    email: {
      options: {
        validators: Validators.required
      },
      render: TextInput,
      meta: {
        label: "youremail@domain.com",
        message: "Email",
        for: "email",
        id: "email",
        name: "email"
      }
    },
    phone: {
      render: TextInput,
      meta: {
        label: "+1(707)716-9325",
        for: "phone",
        id: "phone",
        name: "phone"
      }
    },/*
    portal: {
      render: SelectInput,
      meta: {
        label: "From where are you submitting this form?",
        for: "portal",
        id:"00N5w00000PU8A4",
        name:"00N5w00000PU8A4",
        select: [
          {option: "comidaforfamilias.org"},
          {option: "Volunteermatch"},
          {option: "Idealist"},
          {option: "RemoteHub"},
        ]
      }
    },*/
    leadsource: {
      render: SelectInput,
      meta: {
        label: "Where did you hear from us?",
        for: "lead_source",
        id: "lead_source",
        name: "lead_source",
        select: [
          {option: "Advertisement"},
          {option: "Customer Event"},
          {option: "Volunteer/Employee Referral"},
          {option: "Friend Referral"},
          {option: "Google AdWords"},
          {option: "Other"},
          {option: "Partner"},
          {option: "Purchased List"},
          {option: "Conference"},
          {option: "Hackathon"},
          {option: "Webinar"},
          {option: "Website"},
        ]
      },
    },
    message: {
      options: {
        validators: Validators.required
      },
      render: Textarea,
      meta: {
        label: "Why are you contacting us?\n(e.i. need a keynote speaker, to volunteer as ___, need to maintain status)",
        message: "Comment",
        name: "description"
      }
    },
    $field_0: {
      isStatic: false,
      render: ({invalid, meta: {handleReset}}) => (
        <div className="cf-form__actions">
          <button className="cf-btn--primary-o"
                  type="button"
                  onClick={handleReset}><span>Reset</span>
          </button>
          <button className="cf-btn--primary"
                  type="submit"
                  id="submit-form"
                  disabled={invalid}><span>Submit</span>
          </button>
        </div>
      )
    }
  },
}
