import React from "react";
import {Link} from "react-router-dom";

import "../../styles/layout/section-promo.scss";
import "../../styles/components/buttons/btn-white-o.scss";

import {OPPORTUNITIES} from "../../constants/constants";

export default function Steps() {
  return (
    <section className="cf-section-promo">
      <div className="cf-section-promo__positionimg">&nbsp;</div>
      <div className="cf-section-promo__body">
        <h3 className="cf-title">{OPPORTUNITIES.title}</h3>
        <ul className="cf-section-promo__info">
          {OPPORTUNITIES.volunteersteps.list.map((item, index) => {
            return (
              <li key={index}>
                {/*Used to be Link, but changed it so it redirects to external website.*/}
                <a href={item.link}
                      target="_blank" rel="noopener noreferrer"
                      className='cf-subtitle'>
                  <span>{item.title}</span>
                </a>
                <p>{item.description}</p>
              </li>
            )
          })}
        </ul>
      </div>
    </section>
  );
}
