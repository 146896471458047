import React from "react";

import '../styles/components/switcher.scss'

class Switcher extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      selectedValue: props.defaultValue
    };
  }

  handleChange = e => {
    this.setState({
      selectedValue: e.target.value
    });
    this.props.updateData(e.target.value)
  };

  render() {

    return (
      <React.Fragment>
        <div className="cf-switcher">
          {this.state.data.map((item, index) => {
            return (
              <label className="cf-switcher__btn"
                     htmlFor={`cf${item.id}`}
                     key={index}>
                <input className="cf-switcher__input"
                       id={`cf${item.id}`}
                       checked={this.state.selectedValue === item.name}
                       onChange={this.handleChange}
                       value={item.name}
                       type="radio"/>
                <span className="cf-switcher__label">{item.name}</span>
              </label>
            )
          })}
        </div>
      </React.Fragment>
    );
  }
}

export default Switcher;
