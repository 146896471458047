import React from "react";

import {
  PROJECT_IN_PROGRESS,
  PROJECT_UPCOMING,
  PROJECTS,
  USERS_INFORMATION
} from "../constants/constants";

import Hero from "../components/Hero";
import Banner from "../components/Banner";
import DateCard from "../components/DateCard";
import NoData from "../components/NoData";
import Switcher from "../components/Switcher";
import Badge from "../components/Badge";
import ListStyled from "../components/ListStyled";
import Loading from "../components/Loading";

import noImg from "../images/no_img.png";

import { getProjects } from '../services/projectService'

class Projects extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      setLoading: {
        loadingStatus: true
      },
      switcher: PROJECTS.switcherList,
      filterBy: PROJECTS.switcherList[0].name,
      userInfo: USERS_INFORMATION
    };
  }

  componentDidMount() {
    getProjects()
      .then((res) => {
        this.setState({
          data: res,
          setLoading: { loadingStatus: false }
        });
      })
      .catch((e) => console.log(e))
  }

  render() {
    const cardListContent = () => {
      return (
        this.state.data.map((item, index) => {

          const checkPhoto = (item) => {
            return { backgroundImage: item.imgPreview ? `url(${item.imgPreview})` : `url(${noImg})` }
          }

          const badgeContent = () => {
            return <Badge text={item.status}
              badgeType={item.status === PROJECT_UPCOMING ? "primary" : item.status === PROJECT_IN_PROGRESS ? "secondary" : null}
              classBadge="cf-card-preview__badge" />
          }

          return (
            <div className="cf-card-preview"
              key={index}>
              <div className="cf-card-preview__img">
                <div className="cf-img-animate"
                  style={checkPhoto(item)}>&nbsp;</div>
              </div>
              <div className="cf-card-preview__body">
                <div className="cf-card-preview__info">
                  {badgeContent()}
                  <h3 className="cf-card-preview__title">{item.title}</h3>
                  {/*<DateCard text={`Start Date: ${item.createDate}`}/>*/}
                  <div>{item.description}</div>
                  {item.websiteLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.websiteLink}
                      rel="noopener noreferrer"
                      target="_blank">Website</a>
                  )}
                  {item.videoLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.videoLink}
                      rel="noopener noreferrer"
                      target="_blank">Youtube</a>
                  )}
                  {item.appleLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.appleLink}
                      rel="noopener noreferrer"
                      target="_blank">Apple Store</a>
                  )}
                  {item.playstoreLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.playstoreLink}
                      rel="noopener noreferrer"
                      target="_blank">Google PlayStore</a>
                  )}
                  {item.facebookLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.facebookLink}
                      rel="noopener noreferrer"
                      target="_blank">Facebook</a>
                  )}
                  {item.instagramLink && (
                    <a className="cf-link--primary"
                      style={{ margin: 20 + "px" }}
                      href={item.instagramLink}
                      rel="noopener noreferrer"
                      target="_blank">Instagram</a>
                  )}
                </div>
              </div>
            </div>
          )
        })
      )
    }

    /**
     * Check - has News/Events or empty.
     * @returns {*}
     */
    const content = () => {
      if (this.state.data.length > 0) {
        return cardListContent()
      } else {
        return <NoData text={`There is no Projects yet...`} />
      }
    }

    /**
     * Update this.state.filterBy - with selected value for filter list of News||Events.
     * @param {string} value - of selected Tab from Switcher Component.
     */
    const updateData = (value) => {
      this.setState({
        filterBy: value,
      })
      getProjects()
        .then((res) => {
          this.setState({
            data: value === PROJECTS.switcherList[0].name ? res : res.filter(item => item.status === value),
            setLoading: { loadingStatus: false }
          });
        })
        .catch((e) => console.log(e))
    }

    return (
      <React.Fragment>
        <Hero hero="cf-bg--projects">
          <Banner title="projects"
            subtitle="Check out all of the projects we plan to do, are in progress of doing, and what we have already done. If you want to volunteer to do a different project, you may submit a proposal to do a project at info@comidaforfamilias.com"
          />
        </Hero>
        <section className="cf-section">
          <div className="cf-section__container cf-padding-t-base">
            <Switcher data={this.state.switcher}
              defaultValue={this.state.filterBy}
              updateData={updateData} />
            <div className="cf-card-list cf-card-preview--small">
              {this.state.setLoading.loadingStatus ? (
                <Loading />
              ) : content()}
            </div>
          </div>
        </section>
        <section className="cf-section--gray">
          <div className="cf-section__container">
            <p>{this.state.userInfo.info}</p>
            <ListStyled data={this.state.userInfo.links} />
          </div>
        </section>
      </React.Fragment>
    );
  }
};

export default Projects;

