import React, { Component } from 'react';

import { Bar, HorizontalBar } from 'react-chartjs-2';
export default class Button extends Component {
  render() {
    const dataBarViews = {
      labels: ['Youtube','Website'],
      datasets: [
        {
          label: 'Views',
          backgroundColor: '#EC932F',
          borderColor: 'rgb(255, 227, 0)',
          borderWidth: 1,
          hoverBackgroundColor: ' rgb(255, 227, 0)',
          hoverBorderColor: ' rgb(255, 227, 0)',
          data: [11087, 6700]
        }
      ]
    };

    const dataBarSubs = {
      labels: ['Youtube', 'Instagram', 'Linkedin', 'Facebook', 'Website'],
      datasets: [
        {
          label: 'Followers',
          backgroundColor: 'rgba(23, 32, 57, 1)',
          borderColor: 'rgba(255,99,132,0)',
          borderWidth: 1,

          //color-primary-light = rgba(252, 159, 118, 1)
            //color-primary = rgba(231, 103, 48, .8)
            //color-secondary = rgba(56, 117, 152, 1)
            //color-secondary-dark = rgba(23,32,57,1)
            //random yell = rgb(255, 227, 0) #FFE300
            //bright af orange = #FF7800 rgb(255, 120, 0)
          hoverBackgroundColor: 'rgba(23, 32, 57, 1)',
          hoverBorderColor: 'rgba(231, 103, 48, 1)',
          data: [300, 146, 155, 146, 146, 0]
        }
      ]
    };

    return (
      <div className="cf-grid">
        <div className="cf-grid__col">
          <h4>Views (2021)</h4>
          <Bar
            data={dataBarViews} 
          />
        </div>
        <div className="cf-grid__col">
          <h4>Followers (2021)</h4>
          <Bar
            data={dataBarSubs}
          />
        </div>

      </div>
    );

  }
}
