import React from 'react'
import {Link} from 'react-router-dom'
import ShowMore from 'react-show-more'

import {getTeam} from '../services/teamService'

import Loading from '../components/Loading'
import Hero from '../components/Hero'
import Title from "../components/Title";

import {TEAM_PAGE} from "../constants/constants";

import '../styles/override/show-more.scss'
import '../styles/pages/team.scss'

import LinkedinIcon from 'mdi-react/LinkedinIcon'
import FacebookIcon from "mdi-react/FacebookIcon";

import noImg from "../images/no_photo.png";

class TheTeam extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      becomeAPartContent: TEAM_PAGE.becomeAPart,
      data: [],
      setLoading: {
        loadingStatus: true
      }
    };
  }

  componentDidMount() {
    getTeam()
      .then((res) => {
        this.setState({
          data: res,
          setLoading: {loadingStatus: false}
        });
      })
      .catch((e) => console.log(e))
  }

  render() {
    const checkPhoto = (item) => {
      return {backgroundImage: item.img ? `url(${item.img})` : `url(${noImg})`}
    }

    const boardMember = 'officer';
    const volunteerMember = 'volunteer';

    /**
     * Function that returns the volunteer/member profile card...only for
     * visible status that is true.
     * @param {Array} volunteers.
     * @param {string} status - visibility status.
     * @returns {*}
     */
    const volunteerCard = (volunteers, status) => {
      return (
        <div className="cf-team__grid">
          {volunteers.map((item, index) => {
            if (item.status === status && item.visible === true) {
              return (
                <div className="cf-team__grid-col" key={index}>
                  <div className="cf-team__card">
                    <div className="cf-team__card-img"
                         aria-label='Volunteer photo'
                         style={checkPhoto(item)}>
                    </div>
                    <ul className="cf-team__card-body">
                      <li className="cf-team__card-title">{item.name}</li>
                      <li>{item.position}</li>
                      {item.socials.length && (
                        <li className="cf-team__card-socials">
                          {item.socials.map((soc, index) => {
                            if (soc.link) {
                              return (
                                <a href={soc.link}
                                   key={index}
                                   target="_blank"
                                   rel="noopener noreferrer"
                                   >{soc.name === 'linkedin' ? <LinkedinIcon/> : <FacebookIcon/>}</a>
                              )
                            } else return null;
                          })}
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
              )
            } else return null
          })}
        </div>
      )
    }

    const content = () => {
      const teamMembersWords = this.state.data.filter(item =>
        item !== undefined && item.visible === true && item.description !== '' ? item : null);

      return (
        <React.Fragment>
          <section className="cf-section">
            <div className="cf-section__container">
              <Title sectionSize text='Officers'/>
              {volunteerCard(this.state.data, boardMember)}
            </div>
          </section>

          <section className="cf-section--gray">
            <div className="cf-section__container">
              <Title sectionSize text='Volunteers'/>
              {volunteerCard(this.state.data, volunteerMember)}
            </div>
          </section>

          <section className="cf-section">
            <div className="cf-section__container">
              <Title sectionSize text="Hear why "/>
              <div className="cf-card-list cf-card-preview--small">
                {teamMembersWords.map((item, index) => {
                  return (
                    <div className="cf-card-preview"
                         key={index}>
                      <div className="cf-card-preview__img">
                        <div style={checkPhoto(item)}
                             className="cf-img-animate"
                             aria-label='Volunteer photo'>
                        </div>
                      </div>
                      <div className="cf-card-preview__body">
                        <div className="cf-card-preview__info">
                          <Title text={item.name}
                                 alignLeft
                                 primaryText={item.status}/>
                          <strong>{item.position}</strong>
                          <div className="cf-donate__comment">
                            <ShowMore lines={3}
                                      anchorClass="cf-show-more"
                                      more='Show more'
                                      less='Show less'>
                              {item.description}
                            </ShowMore>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </section>
        </React.Fragment>
      )
    }

    return (
      <React.Fragment>
        <Hero hero='cf-bg--volunteer'>
          <Title text='Meet'
                 bigSize
                 primaryText='Our Team'/>
          <p>{this.state.becomeAPartContent.text}</p>
          <a href={this.state.becomeAPartContent.buttonLink}
                className='cf-btn--white-o'>
            <span>{this.state.becomeAPartContent.buttonText}</span>
          </a>
        </Hero>
        {this.state.setLoading.loadingStatus ? (
          <Loading/>
        ) : content()}
      </React.Fragment>
    )
  }
}

export default TheTeam
