import React, {Component} from "react";
import {NavLink} from "react-router-dom";

import {NAVIGATION_MENU_SIDEBAR} from "../constants/constants"

import "../styles/layout/nav-sidebar.scss";

import MenuIcon from "mdi-react/MenuIcon";

class NavSidebar extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);

    this.state = {
      sidebarVisible: false,
      data: NAVIGATION_MENU_SIDEBAR,
    };
  }

  handleClick() {
    if (!this.state.sidebarVisible) {
      // attach/remove event handler
      document.addEventListener('click', this.handleOutsideClick, false);
    } else {
      document.removeEventListener('click', this.handleOutsideClick, false);
    }

    this.setState(prevState => ({
      sidebarVisible: !prevState.sidebarVisible,
    }));
  }

  handleOutsideClick(e) {
    // ignore clicks on the component itself
    if (this.node.contains(e.target)) {
      return;
    }

    this.handleClick();
  }

  render() {
    return (
      <div className="cf-menu-mobile" ref={node => {this.node = node}}>
        <button className={this.state.sidebarVisible ? `cf-sidebar__btn is-open` : `cf-sidebar__btn`}
                onClick={this.handleClick}>
          <MenuIcon/>
        </button>
        {this.state.sidebarVisible && (
          <aside className="cf-sidebar__nav">
            {this.state.data.map((item, index) => {
              return (
                <NavLink to={item.link}
                         exact
                         className="cf-sidebar__link"
                         activeClassName="is-active"
                         key={index}>{item.text}</NavLink>
              )
            })}
          </aside>
        )}
      </div>
    );
  }
}

export default NavSidebar
