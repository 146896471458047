import React from "react";

import "../styles/components/no-data.scss"

export default function NoData({text, classNoData}) {
  return (
    <div className={`cf-no-data ${classNoData}`}>
      {text}
    </div>
  );
}
