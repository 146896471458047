import React from "react";

import "../../styles/components/inputs/input.scss";

export const SelectInput = ({ handler, touched, hasError, meta }) => (
    <div className='cf-form__row'>
        <div className={touched && hasError("required") && meta.message ? `cf-input is-invalid` : `cf-input`}>
            <select type="text"
                style={{width: 100+"%", height: 100+"%", borderColor: "white"}}

                //for={`${meta.for}`}
                id={`${meta.id}`}
                //maxlength={`${meta.length}`}
                name={`${meta.name}`}
                placeholder={`${meta.label}`} {...handler()} >
                <option value="" className="cf-form__hint">{meta.label}</option>
                {meta.select.map((item, index) => {
                    return (
                        <option value={item.option}>{item.option}</option>
                    )
                })}

            </select>
            <div className="cf-form__hint">
                {touched
                    && hasError("required")
                    && `${meta.message} is required`}
            </div>
        </div>
    </div>
)

