import React, {Component} from "react";

import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Breadcrumbs from "../../components/Breadcrumbs";
import ListStyled from "../../components/ListStyled";

import {GET_INVOLVED_INTERNS} from "../../constants/constants";

import ChevronDownIcon from "mdi-react/ChevronDownIcon";

import "../../styles/layout/section.scss"
import "../../styles/components/card/card-collapse.scss"
import "../../styles/components/card/card-list.scss"
import "../../styles/pages/faq.scss"

import {getInternsFAQList} from "../../services/faqInternService";
import Loading from "../../components/Loading";

class InternsFAQ extends Component {

  constructor(props) {

    super(props);

    const isOpenDescription = {};

    this.state = {
      data: [],
      setLoading: {
        loadingStatus: true
      },
      isOpenDescription
    }
  }

  componentDidMount() {
    getInternsFAQList()
      .then((res) => {
        this.setState({
          data: res,
          setLoading: {loadingStatus: false}
        });
      })
      .catch((e) => console.log(e))
  }

  /**
   * Toggle Description by click.
   * @param index - of dropdown.
   */
  toggleDescription = (index) => {
    const {
      state: {isOpenDescription},
    } = this;
    const isOpen = !!isOpenDescription[index];
    this.setState({isOpenDescription: {[index]: !isOpen}});
  }

  render() {
    const content = () => {
      return (
        <div className="cf-faq__list">
          {this.state.data.map((card, index) => {
            const isOpenClass = this.state.isOpenDescription[index] ? 'is-open' : '';

            return (
              <div className={`cf-card-collapse ${isOpenClass}`}
                   id={card.id}
                   key={index}>
                <h4 className="cf-card-collapse__head"
                    onClick={() => this.toggleDescription(index)}>
                  <span className="cf-card-collapse__title">{card.title}</span>
                  <ChevronDownIcon className="cf-card-collapse__ico"/>
                </h4>
                {this.state.isOpenDescription[index] && (
                  <div className="cf-card-collapse__body">
                    <p>{card.text}</p>
                    {card.list && <ListStyled data={card.list}/>}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )
    }

    return (
      <React.Fragment>
        <Hero hero="cf-bg--projects">
          <Banner title="FAQ For Interns"/>
          <p>There have been no questions asked yet. </p>
        </Hero>
        <section className="cf-section">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={GET_INVOLVED_INTERNS.breadcrumbs.faq}/>

            {this.state.setLoading.loadingStatus ? (
              <Loading/>
            ) : content()}
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default InternsFAQ;