import React from "react";
import Title from "../components/Title.js";

import { TIMELINE } from "../constants/constants.js";

import "../styles/pages/timeline.scss";
import "../styles/layout/section.scss";

export default function timeline() {

    return (
        <section classNam="cf-section">
            <div className="cf-section__container">
                <Title sectionSize text="Timeline" />
                <div className="timeline">

                    <ul>
                        {TIMELINE.map((content, index) => {
                            return (
                                <li>
                                    <div className="content">
                                        <h3>{content.title}</h3>
                                        <p>{content.paragraph}</p>
                                    </div>
                                    <div className="time">
                                        <h4>{content.date}</h4>
                                    </div>
                                </li>
                            );
                        })};
                        <div style={{ clear: "both" }}></div>
                    </ul>
                </div>
            </div>
        </section>
    )
}