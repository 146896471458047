import React from "react";

import "../styles/components/hero/hero.scss";

export default function Hero({ style, children, hero, text }) {
  return (
    <header style={style} className={`cf-hero ${hero}`}>
      <div className={`cf-hero__info ${text}`}>{children}</div>
    </header>
  );
}

Hero.defaultProps = {
  hero: "cf-bg--home",
};
