import {DOMAIN_URL} from "../constants/constants";
import axios from "axios";


const checkDate = (dateString) => new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "long",
  hour: 'numeric', minute: 'numeric', second: 'numeric',
  day: "2-digit"
}).format(new Date(dateString))

const TYPE = 'Events';

export const getEvents = async () => {
  const res = await axios.get(DOMAIN_URL + '/events',
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = res.data.map(item => {
    return {
      id: item._id,
      type: TYPE,
      title: item.title,
      location: item.location,
      filterStartDate: item.startDate,
      filterEndDate: item.endDate,
      startDate: checkDate(item.startDate),
      endDate: checkDate(item.endDate),
      date: `${checkDate(item.startDate)} - ${checkDate(item.endDate)}`,
      imgPreview: item.imgPreview,
      link: TYPE,
      descriptionLimit: 200,
      description: item.description
    }
  });

  return data || [];
};

export const getEventById = async (eventId) => {
  const res = await axios.get(`/events/${eventId}`,
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = {
    id: res.data._id,
    type: TYPE,
    title: res.data.title,
    location: res.data.location,
    date: `${checkDate(res.data.startDate)} - ${checkDate(res.data.endDate)}`,
    img: res.data.img,
    link: TYPE,
    buttonLink: res.data.registrationLink || 'https://form.typeform.com/to/x16aab' || res.data.buttonLink,
    buttonText: res.data.buttonText,
    descriptionLimit: 200,
    description: res.data.description
  }

  return data || {};
};
