import React from "react";
import Video from "../videos/vegetables.mp4";

import "../styles/pages/quisines.scss";
import "../styles/components/hero/hero.scss";
import "../styles/components/buttons/btn-white-o.scss";
//import BannerVideo from "../components/BannerVideo";
import Banner from "../components/Banner";
import Hero from "../components/Hero";

import { CEOINTERN } from "../constants/constants";
import AppleDownload from "../icons/appledownload.svg";
import googleplaydownload from "../icons/googleplaydownload.png";

export default function CeointernPage() {
  return (
    <Hero hero="cf-bg--ceointern" text="center">
      <Banner title={CEOINTERN.title} subtitle={CEOINTERN.subtitles} />

      <div className="cf-hero__btn-group">
        {CEOINTERN.buttons.map((item, index) => {
          return (
            <a
              href={item.link}
              key={index}
              target="_self"
              rel="noopener noreferrer"
              className="cf-btn--white-o"
              aria-label={item.ariaLabel}
              id="ceointern-btn"
            >
              <span>{item.text}</span>
            </a>
          );
        })}
      </div>
    </Hero>
  );
}
