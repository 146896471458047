import React from 'react'
import {BeatLoader} from 'react-spinners'

import '../styles/override/beatloader.scss'

const Loading = ({loadingState, loadingClass}) => {
  return (
    <div className={`cf-loading ${loadingClass}`}>
      <BeatLoader
        size={20}
        loading={loadingState}
      />
    </div>
  )
}

export default Loading
