import Positions from "../pages/GetInvolved/Positions";

export const NAVIGATION_MENU = [
  {
    id: 1,
    text: "About",
    dropdown: [
      { id: 2, link: "/about", text: "About" },
      { id: 3, link: "/theteam", text: "The Team" },
    ],
  },
  {
    id: 4,
    text: "Projects",
    dropdown: [
      { id: 6, link: "/projectlist/androidapps", text: "Android Apps" },
      { id: 7, link: "/projectlist/appleapps", text: "Apple Apps" },
      { id: 8, link: "/projectlist/projectlist", text: "Projects" },
    ],
  },
  {
    id: 10,
    text: "Get Involved",
    dropdown: [
      { id: 11, link: "/getinvolved/positions", text: "Volunteer" },
      { id: 12, link: "/getinvolved/internships", text: "Internships" },
      { id: 13, link: "/f1opt", text: "F-1 OPT" },
      { id: 14, link: "/getinvolved/faq", text: "FAQ" },
      { id: 15, link: "/getinvolved/interns_faq", text: "FAQ For Interns" },
    ],
  },
  { id: 16, link: "/media", text: "Media" },
  { id: 17, link: "/lifestyle", text: "Lifestyle" },
  {
    id: 18,
    text: "News and Events",
    dropdown: [
      { id: 19, link: "/newsevents/events", text: "Events" }, //link: '/NewsEvents/Events', text: 'Events'
      { id: 20, link: "/newsevents/news", text: "News" }, //link: '/NewsEvents/News', text: 'News'
    ],
  },

  /*find out how to fix it because for right now, it is only working when you are on another page.
    {
      id: 14,
      text: 'Contact Us',
      link: "/#contacts"
    }
    */
];

export const DOMAIN_URL = "https://comidaforfamilias.com"

export const ABOUT = {
  name: "Comida For Familias, Inc.",
};

export const NAVIGATION_MENU_SIDEBAR = [
  { id: 1, link: "/", text: "Home" },
  { id: 2, link: "/about", text: "About" },
  { id: 3, link: "/projectlist/projectlist", text: "Projects" },
  { id: 4, link: "/projectlist/androidapps", text: "Android Apps" },
  { id: 5, link: "/projectlist/appleapps", text: "Apple Apps" },
  { id: 6, link: "/newsevents/events", text: "Events" },
  { id: 7, link: "/newsevents/news", text: "News" },
  { id: 8, link: "/getinvolved/internships", text: "Internships" },
  { id: 9, link: "/getinvolved/positions", text: "Volunteer" },
  { id: 10, link: "/f1opt", text: "F-1 OPT" },
  { id: 11, link: "/getinvolved/faq", text: "FAQ" },
  { id: 12, link: "/getinvolved/interns_faq", text: "FAQ For Interns" },
  { id: 13, link: "/theteam", text: "The Team" },
  { id: 14, link: "/media", text: "Media" },
  //{ id: 12, link: '/#contacts', text: 'Contact Us' }, this one isn't working at all.
  { id: 15, link: "/donate", text: "Donate" },
];

export const GIVING_BACK = [
  {
    title: "Projects",
    date: "1 January 2022",
    img: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1599077326/comidaforfamilias-site/home/cff_home_project_card_gif_cc9h8p.gif",
    alt: "Flutter Puzzle for the Flutter Puzzle Hackathon sponsored by Google, Rive, and Very Good Ventures",
    info: "Check out what we are working on! Our projects are submitted to competitions and published on the web, the Google Playstore, and/or the Apple Store. It doesn't matter if we win or lose, it is about creating something real. ",
    linkpage: "projectlist/projectlist",
    videoLink: "https://www.youtube.com/embed/eXryfnN2IyA",
  },

  {
    title: "News and Events", //News and Events
    date: "20 October 2021",
    img: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1599084855/comidaforfamilias-site/home/cff_home_news_n_event_card.gif",
    alt: "Training Women Entreprenuers",
    info: "Our team does more than just design apps and code. We strive to foster and strengthen connections within our team as much as we strive to do so outside of it. That is why we like to provide learning resources and invite community members to our events!",
    linkpage: "newsevents/events", //NewsEvents/Events
    videoLink: "https://www.youtube.com/embed/5u91K0PaoOc",
  },

  {
    title: "Volunteer Positions",
    date: "5 April 2022",
    img: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1599083390/comidaforfamilias-site/home/cff_home_volunteer_card.gif",
    alt: "A dish from the Quisines recipe app",
    info: `Find out what volunteer positions are available to you. These positions range from coding, designing, cooking, hosting your own series, and many more.
     If you have an idea for an activity that is not listed, contact an officer of the organization to discuss how we can conduct the activity while accomplishing charitable purposes.`,
    linkpage: "getinvolved/positions",
    videoLink: "https://www.youtube.com/embed/nuauVKmsTAI",
  },
];

export const GET_INVOLVED = {
  breadcrumbs: {
    opportunity: [
      { id: 1, name: "Positions" },
      { id: 2, name: "FAQ", link: DOMAIN_URL + "/getinvolved/faq" },
    ],
    faq: [
      { id: 1, name: "Positions", link: DOMAIN_URL +"/getinvolved/positions" },
      { id: 2, name: "FAQ" },
    ],
  },
};

export const GET_INVOLVED_INTERNS = {
  breadcrumbs: {
    opportunity: [
      { id: 1, name: "Internships" },
      { id: 2, name: "FAQ", link: DOMAIN_URL + "/getinvolved/interns_faq" },
    ],
    faq: [
      { id: 1, name: "Internships", link: DOMAIN_URL + "/getinvolved/internships" },
      { id: 2, name: "FAQ" },
    ],
  },
};

// NewsEvents Page.
export const EVENT_TYPE = "Events"; //EVENTS
export const NEWS_TYPE = "News"; // NEWS
export const NEWS_EVENTS_SWITCH_ALL = "All";
export const NEWS_EVENTS_SWITCH_UPCOMING = "Upcoming";
export const NEWS_EVENTS_SWITCH_PAST = "Past";
export const NEWS_EVENTS_SWITCH_CURRENT = "Current";
export const NEWS_EVENTS_SWITCH_TECH = "Tech";
export const NEWS_EVENTS_SWITCH_TOP = "Featured";

export const NEWS_EVENTS = {
  breadcrumbs: {
    news: [
      { id: 1, name: "Events", link: "Events" }, //EVENTS
      { id: 1, name: "News" }, //News
    ],
    events: [
      { id: 1, name: "Events" }, //EVENTS
      { id: 1, name: "News", link: "News" }, //News
    ],
  },
  switcherList: {
    events: [
      { id: 1, name: NEWS_EVENTS_SWITCH_ALL },
      { id: 2, name: NEWS_EVENTS_SWITCH_UPCOMING },
      { id: 3, name: NEWS_EVENTS_SWITCH_PAST },
      { id: 4, name: NEWS_EVENTS_SWITCH_CURRENT },
    ],
    news: [
      { id: 1, name: NEWS_EVENTS_SWITCH_ALL },
      { id: 2, name: NEWS_EVENTS_SWITCH_TECH },
      { id: 3, name: NEWS_EVENTS_SWITCH_TOP },
    ],
  },
};

export const HOW_CAN_I_HELP_CONTENT = {
  title: "Being part of la familia",
  list: [
    {
      id: 1,
      title: "be a volunteer",
      description: `We have many positions available, each diverse
                in their own way so as to provide a niche for anyone with the will to contribute.
                We also provide resources and recognition to those that want to be strongly-affiliated with us,
                along with a free shirt to show that you are one of us!`,
      link: "/getinvolved/positions",
    },
    {
      id: 2,
      title: "Awards",
      description: `Accomplishing activities that benefit the public can be hard work. That is why eligible individuals may be nominated
      for the presidential service award, the congressional award, or other awards that depend on voluntary service with a 501(c)(3) organization.`,
      link: "https://presidentialserviceawards.org/",
    },
    {
      id: 3,
      title: "Volunteer and Trainee Orientation",
      description: `Through our orientation we explain what it means to be strongly-affiliated with our organization and
      what services we provide to the public. Once you have gone through the slideshow, contact us to have a representative present the
      orientation to you and answer any questions that you may have.`,
      link: "http://bit.ly/comidaforfamilias-orientation",
    },
  ],
};

export const SHARE_LIST = [
  {
    id: 1,
    link: "",
    ariaLabel: "facebook",
    ico: "facebook",
  },
  {
    id: 2,
    link: "",
    ariaLabel: "linkedin",
    ico: "linkedin",
  },
  {
    id: 3,
    link: "",
    ariaLabel: "twitter",
    ico: "twitter",
  },
];

export const CONTACTS_CONTENT = {
  address: "131 Sunset Avenue, Suite E, PMB 254 Suisun City, CA, 94585",
  ceo: "Antonio Mendieta, CEO Comida For Familias, Inc.",
  info: `Comida For Familias, Inc. is
        readily available to have any speaking
        arrangements from a wide range of topics.
        Schedule an appearance with us today! If you
        plan to affiliate with us, describe in detail
        any activities that you have planned.`,
  phone: "707-716-9325",
  email: "info@comidaforfamilias.com",
  socials: [
    {
      href: "https://www.facebook.com/comidaforfamilias",
      ariaLabel: "facebook",
      ico: "facebook",
    },
    {
      href: "https://www.instagram.com/comida_for_familias_inc/",
      ariaLabel: "instagram",
      ico: "instagram",
    },
    {
      href: "https://www.linkedin.com/company/comida-for-familias-inc/",
      ariaLabel: "linkedin",
      ico: "linkedin",
    },
    {
      href: "https://twitter.com/comidafor",
      ariaLabel: "twitter",
      ico: "twitter",
    },
    {
      href: "https://www.youtube.com/channel/UCmDrvxZ2ftlh8w7qBnNWhYA/?sub_confirmation=1",
      ariaLabel: "youtube chanel",
      ico: "youtube",
    },
  ],
  copyright: "©2022 Comida For Familias, Inc",
};

// Project Page.
export const PROJECT_ALL = "All";
export const PROJECT_COMPLETED = "Completed";
export const PROJECT_IN_PROGRESS = "In Progress";
export const PROJECT_UPCOMING = "Upcoming";

export const PROJECTS = {
  switcherList: [
    { id: 1, name: PROJECT_ALL },
    { id: 2, name: PROJECT_UPCOMING },
    { id: 3, name: PROJECT_IN_PROGRESS },
    { id: 4, name: PROJECT_COMPLETED },
  ],
};

export const USERS_INFORMATION = {
  info: `If you would like to contribute to our progress and dont have much time then here is a simple way for making a difference.
  We have created some simple user surveys which will be used only as research data for our current and upcoming projects.
  It would only take a couple of minutes of your time. It would be a great help.`,
  links: [
    { id: 1, text: "Website redesign", link: "/" },
    { id: 2, text: "Social media platform", link: "/" },
    { id: 2, text: "New project ideas", link: "/" },
  ],
};

export const TEAM_PAGE = {
  becomeAPart: {
    text: `
    Find out who is a part of our familia and why they decided to be a part of our organization.
    `,
    buttonText: "Become A Part Of Our Familia",
    buttonLink: "https://form.typeform.com/to/x16aab",
  },
};

export const DONATE_DEV = "dev";
export const DONATE_PROJECT = "project";
export const DONATE_FINANCING = "financing";

export const CODES = {
  title: "A few of the codes that Comida for Familias operates under:",
  list: [
    { id: 1, code: "B80", text: "Student Services" },
    { id: 2, code: "K40", text: "Nutrition" },
    { id: 3, code: "J22", text: "Job Training" },
    {
      id: 4,
      code: "T50",
      text: "Philanthropy, Charity, and Voluntarism Promotion",
    },
    { id: 5, code: "U40", text: "Engineering and Technology" },
    { id: 6, code: "U41", text: "Computer Science" },
  ],
};

export const DONATE_PAGE = {
  title: "Donations are tax-deductible",
  info: `Donations made to Comida For Familias, Inc. are tax deductible and will be used to continue maintaining our resouces and accomplishing our charitable purposes.`,
  btnDonateLink: "https://www.paypal.com/us/fundraiser/charity/3792494",
  btnDonateText: "Donate",
  donationsFlow: {
    title: "Your donations cover the cost of",
    list: [
      {
        id: 1,
        ico: DONATE_DEV,
        text: "Keeping volunteers’ developer accounts active",
      },
      {
        id: 2,
        ico: DONATE_PROJECT,
        text: "The formation of new projects that Comida for Familias will undertake in the future",
      },
      {
        ico: DONATE_FINANCING,
        id: 3,
        text: "Funding toward other nonprofit ventures",
      },
    ],
  },
  notice: {
    title: "Your contributions will not go without notice",
    info: `Your name will be recorded down on our list of donors and you will be subscribed to the latest updates
          to what we’re working on. We’re also working on a swag bag, ethically sourced and
          supportive of small enterprises that contribute to the community. More to come.`,
  },
  thanks: {
    title: "many thanks to your contributions toward a greater cause!",
    list: [
      //add donors here.
      { name: "MIJ All Plastering", link: "/" },
    ],
    info: "Any donations more than $1,000 will appear here",
  },
};

//text for the home page
export const HOME_PAGE = [
  {
    image: "cf-bg--home-slide-1",
    title: "Leveraging Technology to efficiently distribute food and resources",
    btnText: "Activities",
    btnLink:
      "https://medium.com/comidaforfamilias/what-activities-do-volunteers-do-with-comida-for-familias-inc-755187728d23",
    info: `Comida For Familias, Inc. is a 501(c)(3) scientific research organization that focuses on using technology for public benefit. Our mission is to utilize the skills of trained individuals to accomplish scientific, educational, and charitable purposes.`,

    subtitle: `Comida For Familias, Inc. is a scientific research organization that was founded on the idea that technology can be utilized to bring people and communities together to solve humanities' most basic needs.  `,
    note: {
      text: `Official Volunteers and Trainees will be provided with an organization account that will grant access to resources needed to accomplish activities`,
      link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c",
    },
    buttons: [
      {
        text: "Projects and Activities",
        link: "https://comidaforfamilias.com/projectlist/projectlist",
      },
      {
        text: "See Positions",
        link: "https://comidaforfamilias.com/getinvolved/positions",
      },
    ],
  },
  {
    image: "cf-bg--home-slide-2",
    title: "Participate In Purposeful Projects And Activities",
    btnText: "Activities",
    btnLink:
      "https://medium.com/comidaforfamilias/what-activities-do-volunteers-do-with-comida-for-familias-inc-755187728d23",
    info: `Comida For Familias welcomes people of all skill sets to volunteer and advance their own funds to accomplish charitable activities. There is an annual $270 registration fee to join the organization as a recognized and supervised familia volunteer.`,

    subtitle: `Comida For Familias, Inc. is a scientific research organization that was founded on the idea that technology can be utilized to bring people and communities together to solve humanities' most basic needs.  `,
    note: {
      text: `Official Volunteers and Trainees will be provided with an organization account that will grant access to resources needed to accomplish activities`,
      link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c",
    },
    buttons: [
      {
        text: "Recognition and Supervision",
        link: "https://medium.com/comidaforfamilias/what-does-it-mean-to-be-an-official-volunteer-with-cff-c1199c28f798",
      },
      {
        text: "Sign Up",
        link: "https://form.typeform.com/to/x16aab",
      },
    ],
  },
  {
    image: "cf-bg--home-slide-3",
    title: "Join our Familia! And Create Something New with Others Like You",
    btnText: "Activities",
    btnLink:
      "https://medium.com/comidaforfamilias/what-activities-do-volunteers-do-with-comida-for-familias-inc-755187728d23",
    info: `Follow us on social media to be notified when we have events and activities. If you are looking for supervised training, contact us using the button below. `,

    subtitle: `Comida For Familias, Inc. is a scientific research organization that was founded on the idea that technology can be utilized to bring people and communities together to solve humanities' most basic needs.  `,
    note: {
      text: `Official Volunteers and Trainees will be provided with an organization account that will grant access to resources needed to accomplish activities`,
      link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c",
    },
    buttons: [
      {
        text: "Follow Us",
        link: "https://linktr.ee/comidaforfamilias",
      },
      {
        text: "Contact Form",
        link: "https://comidaforfamilias.com/#contacts",
      },
    ],
  },
];

export const DONORS = {
  title: "Donors",
  list: [{ name: "MIJ All Plastering Systems" }],
};

export const POST = {
  title: "Publications on Medium",
  description: `Check out the list of articles prepared by our experts and become part of our team.`,
  btn: {
    txt: "Read more on Medium",
    link: "https://medium.com/comidaforfamilias",
  },
};

export const F1OPT_PAGE = {
  title: "Training for F-1 Students",
  description: `F-1 students can volunteer and train with Comida For Familias, Inc. to extend their stay
  while learning in professional environment. The service volunteers/trainees provide must be related
  to the foreign national's degree program.`,
  promo_info: [
    {
      title: "Post-Completion Opt",
      link: "#",
      description: "this is what Post OPT is",
    },
    {
      title: "STEM OPT Extension",
      link: "#",
      description: "this is what Post OPT is",
    },
  ],
  selfemployed_section: {
    id: "1",
    title: "Self-Employment",
    paragraph: "hello world",
    link: "#",
    buttonText: "Learn More",
  },
  priceCards: [
    {
      id: "official_volunteer",
      header: "Official Volunteer",
      price: 2000,
      per: "/month",
      details: [
        {
          id: 1,
          text: "No resources",
        },
        {
          id: 2,
          text: "Report OPT hours",
        },
        {
          id: 3,
          text: "Indirectly supervised",
        },
        {
          id: 4,
          text: "Supervised 20 hours a week",
        },
      ],
      link: "https://www.paypal.com/us/fundraiser/charity/3792494",
      btnText: "start now!",
      showModal: false,
      modalTitle: "Schedule a Time to Talk",
      modalInfo: `Want to discuss the details of maintaining status with Comida For Familias, Inc?
      Fill the form below to schedule a time to talk with a supervisor. We will get back to you within 3 business days.`,
    },
    {
      id: "unpaid_intern",
      header: "Unpaid Intern",
      price: 5000,
      per: "/month",
      details: [
        {
          id: 1,
          text: "Trainee resources",
        },
        {
          id: 2,
          text: "Report OPT hours",
        },
        {
          id: 3,
          text: "Directly Supervised by a mentor",
        },
        {
          id: 4,
          text: "Supervised 20 hours a week",
        },
      ],
      link: "https://www.paypal.com/us/fundraiser/charity/3792494",
      btnText: "start now!",
      showModal: false,
      modalTitle: "Schedule a Time to Talk",
      modalInfo: `Want to discuss the details of maintaining status with Comida For Familias, Inc?
      Fill the form below to schedule a time to talk with a supervisor. We will get back to you within 3 business days.`,
    },
    {
      id: "ceo",
      header: "CEO of a Business",
      price: 10000,
      per: "",
      details: [
        {
          id: 1,
          text: "Start your own business",
        },
        {
          id: 2,
          text: "Create a 501(c)(3) org",
        },
        {
          id: 3,
          text: "Change to H-1B status",
        },
        {
          id: 4,
          text: "200+ hours of training",
        },
      ],
      link: "https://www.paypal.com/us/fundraiser/charity/3792494",
      btnText: "start now!",
      showModal: false,
      modalTitle: "Schedule a Time to Talk",
      modalInfo: `Want to discuss the details of maintaining status with Comida For Familias, Inc?
      Fill the form below to schedule a time to talk with a supervisor. We will get back to you within 3 business days.`,
    },
  ],
};

export const OPPORTUNITIES = {
  title: "Volunteer Registration",
  // subtitle: `Comida For Familias provides a wide variety of positions for tech-focused and other skilled individuals to
  // participate in activities to accomplish charitable purposes. The organization's goal is to develop and improve
  // communities through the innovative use of technology with the knowledge and skills of trained individuals.
  // Official volunteers may be equipped with resources related to their activity to ensure success.`,
  subtitle: `Individuals who perform hours of service for a public agency for civic, charitable, or humanitarian reasons without promise, expectation or reciept of compensation for services rendered are considered volunteers during hours of service.`,
  strongaffiliate: {
    title: "Strongly Affiliated Volunteer",
    paragraph: `As a strongly affiliated volunteer, you will be supervised for the activities that you accomplish.
      So when it comes time to show any proof of your participation for school or a new job, we will write a letter of your participation.`,
    link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c#:~:text=Being%20paid%20for%20work%20and%20services%20is%20the,that%20it%20is%20an%20nonprofit%20organization%20that%20receives",
    id: 1,
    buttontext: "Learn More",
  },
  payment: {
    title: "Not A Traditional Charity",
    paragraph: `Comida For Familias, Inc. is a 501(c)(3), 509(a)(2) scienfic research organization that is funded by investments and permitted sources of revenue. Volunteers are permitted to receive supervision and/or bona-fide training; merely gaining additional work experience is not permitted. There is a $270 registration fee to become affiliated with our organization.`,
    link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c#:~:text=Being%20paid%20for%20work%20and%20services%20is%20the,that%20it%20is%20an%20nonprofit%20organization%20that%20receives",
    id: 1,
    buttontext: "More Details",
  },
  volunteersteps: {
    title: "Steps To Being A Volunteer",
    list: [
      {
        id: 1,
        title: "Decide What You Want To Volunteer As",
        description:
          "Scroll down to the bottom of the page and see what positions you can find within Comida For Familias, Inc. Each has a list of things you can expect when you take up the position.",
        link: "",
      },
      {
        id: 2,
        title: "Sign a Confidentiality Agreement",
        description:
          "If your position has you dealing with data, code, or any other sensitive information, then you must fill a confidentiality agreement.",
        link: "https://res.cloudinary.com/comida-for-familias-inc/image/upload/v1669270227/comidaforfamilias-site/Volunteer_Confidentiality_wg5jai.pdf",
      },
      {
        id: 3,
        title: "Fill a Volunteer Registration Form",
        description:
          "Once you agree to be a volunteer with Comida For Familias, Inc. fill out a registration form to get access to your Google G-Suite Account, Apple Developer account, access to our online video courses and more. As long as you fill this out, we will email the other two documents and consider you as a strong affiliate.",
        link: "https://bit.ly/cff_volunteer_form",
      },
    ],
  },
};

export const INTERNSHIPS = {
  title: "Internships",
  subtitle: `Potential interns are welcome to join us in creating projects that will tie in their formal education for academic credit.
  Understand that there is no promise of compensation that will suggest that the intern is an employee or that Comida For Familias is their employer.
  Interns will be using tools that are used within the industry and will be learning skills alongside others within their fields.`,
};

export const QUISINES = {
  title: "Recipes for Nutritious Quisines ",
  subtitles:
    "Download today for nutritious recipes with easy to follow video guides from culinary contributors. ",
  note: {
    text: `Click here to Learn more!`,
    link: "https://www.facebook.com/groups/quisinesrecipes/",
  },
  buttons: [
    {
      ico: "androiddownload",
      text: "Download for Android",
      ariaLabel: "Download for Android",
      link: "https://play.google.com/store/apps/details?id=com.comidaforfamilias.quisine",
    },
    {
      ico: "appledownload",
      text: "Download for Apple",
      ariaLabel: "Download for Apple",
      link: "https://apps.apple.com/us/app/quisines/id1578552563",
    },
  ],
};

export const CEOINTERN = {
  title: "Become CEO Of Your Own Organization Within 3 Months!",
  subtitles: "Click below to go to the Ceointern.org",
  buttons: [
    {
      text: "Continue to ceointern.org",
      ariaLabel: "Continue to ceointern.org",
      link: "https://ceointern.com",
    },
  ],
};

export const MEDIA_PAGE = {
  image: "cf-bg--home-slide-1",
  title: "Media Page",
  btnText: "Activities",
  btnLink:
    "https://medium.com/comidaforfamilias/what-activities-do-volunteers-do-with-comida-for-familias-inc-755187728d23",
  info: `Find out about our organizaiton's values, our branding guidelines, and where we have made our appearances.
  Contact us when you want to feature us. `,

  subtitle: `Find out about our organizaiton's values, our branding guidelines, and where we have made our appearances.
  Contact us when you want to feature us. `,
  note: {
    text: `Official Volunteers and Trainees will be provided with an organization account that will grant access to resources needed to accomplish activities`,
    link: "https://medium.com/comidaforfamilias/pay-to-do-charitable-activities-that-will-make-your-familia-proud-808b3853139c",
  },
  buttons: [
    {
      text: "Contact Us",
      link: "https://comidaforfamilias.com/#contacts",
    },
  ],
};

export const MEDIA_PAGE_SECTIONS = {
  guidelines: {
    title: "Branding Guidlines",
    paragraph: `Download this pdf with general rules to accurately represent our brand. Guidelines
    will instruct you on how to use our logo, typography, color pallette, mission, voice, and values. This will help you
    keep your content consistent with ours as well as the other users that utilize our assets.`,
    buttontext: "Download Guidelines",
    link: "https://docs.google.com/presentation/d/1M13JHiz7K2X3GpgQvu_X8kmk8tngCbjpiOphaXjg06Q/edit?usp=sharing",
    id: "media-btn",
  },
  radio: {
    title: "Radio & Podcasts",
    paragraph: `Click below to watch an interview about the organization
    or you can listen to our CEO talk about food insecurity
    with Michelle on KZSU Standford 90.1 FM's Laptop Radio. `,
    buttontext: "Watch Interview",
    link: "https://youtu.be/Sg94iZlONto",
    id: "media-btn",
  },
  hackathons: {
    title: "Hackathons",
    paragraph: `Want to know how which hackathons Comida For Familias, Inc. have competed in? Click the button
     below to go our CEO's Devpost portfolio. He supervises CFFI's volunteers and trainees whenever the organizaiton participates in
     a hackathon. `,
    buttontext: "Hackathon Projects",
    link: "https://devpost.com/Tongre001",
    id: "media-btn",
  },
};

export const TIMELINE = [
  {
    id: "",
    title: "DATE OF INCORPORATION",
    paragraph: `The day when Antonio recieved a letter in the mail stating
    that Comida For Familias, Inc. is incorporated as a 501(c)(3) nonprofit
    public benefit corporation.`,
    date: "8-21-2019",
  },
  {
    id: "",
    title: "TAX-EXEMPTION",
    paragraph: `Recieved a verification letter from the IRS confirming that
    Comida Familias was recognized as a 501(c)(3) organization.`,
    date: "9-17-2019",
  },
  {
    id: "",
    title: "DEV-WEEK 2020",
    paragraph:
      "The organization and its volunteers participated in its first hackathon. ",
    date: "6-28-2020",
  },
  {
    id: "",
    title: "AMPLE",
    paragraph: `Published its first application on the Google Playstore. The purpose of the app was
    to identify how many people were at certain locations so that users can avoid crowds.`,
    date: "8-9-2020",
  },
  {
    id: "",
    title: "NEEDY DELIVERY",
    paragraph: `We published a delivery application for Developer Week 2021 winter hackathon.
    Needy Delivery was built using Native iOS (Swift) and Android (Java) languages.`,
    date: "2-18-2021",
  },
  {
    id: "",
    title: "MAAATH",
    paragraph:
      "Another group published an educational application that had math problems for kids. ",
    date: "2-18-2021",
  },
  {
    id: "",
    title: "QUISINES",
    paragraph: `Created our very own recipe application that is specifically for dieticians and nutritionists to share their quick and healthy recipes to the public.`,
    date: "10-5-2021",
  },
  {
    id: "",
    title: "HACKS FOR HUMANITY 2021",
    paragraph: `Volunteers from the organization competed in the Hacks For Humanity Hackathon hosted by ASU Project Humanity.
    The organization made it as one of the top 3 finalists. `,
    date: "10-11-2021",
  },
  {
    id: "",
    title: "COMIDATICKET.COM (FEEDER)",
    paragraph: `Published a reservation system for food facilities to use to make inventory and consumer management more efficient.`,
    date: "11-24-2021",
  },
];

export const RELAXASSISTANT = {
  title: "Relax, Assistance is on the Way.",
  subtitles:
    "Many people have trouble sleeping at night, and because we like to promote relaxation and sufficient sleep in to a healthy lifestyle, we have created a webpage with plenty of resources to assist in getting good rest.",
  note: {
    text: `Click here to Learn more!`,
    link: "https://www.facebook.com/groups/quisinesrecipes/",
  },
  buttons: [
    {
      ico: "androiddownload",
      text: "Visit Website",
      ariaLabel: "Visit Relax Assistant",
      link: "https://relaxassistant.com/",
    },
    {
      ico: "appledownload",
      text: "Start Listening",
      ariaLabel: "Start listening on Youtube",
      link: "https://www.youtube.com/channel/UC0IJ83qfPOy7ngxMu-KV8yg",
    },
  ],
};

export const LIFESTYLE_PAGE = [
  {
    id: "sleep",
    title: "Relaxation & Healthy Sleep",
    subtitle: "",
    paragraph: `Many people have trouble sleeping at night, and because we like to promote relaxation and sufficient sleep in to a healthy lifestyle, we have created a webpage with plenty of resources to assist in getting good rest.`,
    buttonText: "Start Relaxing",
    link: "https://relaxassistant.com/",
  },
  {
    id: "food",
    title: "Food & Nutrition",
    paragraph: `Wondering what to eat? See what we have cooking in Quisines, our easy-cooking recipe applicaition!`,
    buttonText: "See App",
    link: "https://comidaforfamilias.com/quisines",
  },
  {
    id: "excersice",
    title: "Healthy Sleep",
    paragraph: `We have a large community of skilled individuals from different backgrounds working on a wide range of different projects.`,
    buttonText: "See communities",
    link: "",
  },
  {
    id: "community",
    title: "Community",
    paragraph: `We have a large community of skilled individuals from different backgrounds working on a wide range of different projects.`,
    buttonText: "See communities",
    link: "",
  },
];

export const VOLUNTEERGRID = [
  {
    id: 1,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "Apple Developer Account",
    website: "",
    paragraph:
      "Developers will have their own organization Apple developer account to publish their application on the Apple App Store.",
    link: "https://apps.apple.com/us/developer/comida-for-familias/id1542680065",
  },

  {
    id: 2,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "User Testing",
    website: "usertesting.com",
    paragraph:
      "Our UX researchers use UserTesting for their user research studies to optimize apps and websites.",
    link: "https://www.usertesting.com/resources/customers/comida-familias",
  },

  {
    id: 3,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "G-Suite",
    website: "google.com",
    paragraph:
      "Get your own organization G-suite account which gives you access to Google Ads, Firebase, Docs, Spreadsheets, and more.",
    link: "https://www.google.com/grants/",
  },
  {
    id: 4,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "Medium",
    website: "medium.com",
    paragraph:
      "Establish credibility and gain a following by publishing reports, studies, and tutorials on Medium.",
    link: "https://medium.com/comidaforfamilias/redesigning-comida-for-familias-incs-official-website-15393fd4d42c",
  },
  {
    id: 5,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "Comida For Familias Blog",
    website: "comidaforfamilias.blog",
    paragraph:
      "Did you know we have our own publication website? See what other volunteers have published on our blog website.",
    link: "https://www.usertesting.com/resources/customers/comida-familias",
  },
  {
    id: 6,
    img: "https://tse1.mm.bing.net/th?id=OIP.EWkRmMng6YSM_bkv_ATZAgHaD4&pid=Api&P=0",
    title: "Youtube",
    website: "youtube.com",
    paragraph: `Create videos in the name of the organization and share your expertise and establish credibility.
      Videos will be reviewed before publishing to look professional.`,
    link: "https://www.youtube.com/channel/UCmDrvxZ2ftlh8w7qBnNWhYA/?sub_confirmation=1",
  },
];
