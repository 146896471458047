import React from "react";
import {Link} from "react-router-dom";

import Badge from "../../components/Badge";
import DateCard from "../../components/DateCard";

import noImg from "../../images/no_img.png";

export default function NewsEventsCard({item, badgeText, badgeColor}) {

  const shortDescription = item.description
    .substr(1, item.descriptionLimit);

  const checkPhoto = (item) => {
    return {backgroundImage: item.imgPreview ? `url(${item.imgPreview})` : `url(${noImg})`}
  }

  return (
    <div className="cf-card-preview">
      <div className="cf-card-preview__img">
        <div className="cf-img-animate"
             style={checkPhoto(item)}>&nbsp;</div>
      </div>
      <div className="cf-card-preview__body">
        <Badge text={badgeText}
               badgeType={badgeColor}
               classBadge="cf-card-preview__badge"/>
        <div className="cf-card-preview__info">
        <h3 className="cf-card-preview__title">{item.title}</h3>
          {/*<Link to={`${item.link}/${item.id}`}
                className="cf-card-preview__title">{item.title}</Link>*/}
          <DateCard text={item.date}/>
          {item.description.length > item.descriptionLimit && <p>{shortDescription}...</p>}
          {item.description.length <= item.descriptionLimit &&
          <p>{item.description}</p>}
          {item.buttonLink && (
            <a href={item.buttonLink}
               onClick={window.gtag_report_training}
               target="_blank"
               rel="noopener noreferrer"
               className="cf-link--primary">
              <span>{item.buttonText}</span>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}
