import {DOMAIN_URL} from "../constants/constants";
import axios from "axios";

const checkDate = (dateString) => new Intl.DateTimeFormat("en-GB", {
  year: "numeric",
  month: "long",
  day: "2-digit"
}).format(new Date(dateString))

export const getProjects = async () => {
  const res = await axios.get(DOMAIN_URL + '/projects',
    {headers: {'Content-Type': 'application/json;charset=UTF-8'}});
  const data = res.data.map(item => {
    return {
      id: item._id,
      status: item.status,
      createDate: checkDate(item.createDate),
      title: item.title,
      description: item.description,
      img: item.img,
      imgPreview: item.imgPreview,
      websiteLink: item.websiteLink,
      videoLink: item.videoLink,
      appleLink: item.appleLink,
      playstoreLink: item.playstoreLink,
      facebookLink: item.facebookLink,
      instagramLink: item.instagramLink
    }
  });

  return data || [];
};
