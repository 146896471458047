import React from "react";

import "../../styles/components/inputs/textarea.scss";

export const Textarea = ({handler, touched, hasError, meta}) => (
  <div className='cf-form__row'>
    <textarea className={touched && hasError("required") && meta.message ? `cf-textarea is-invalid` : `cf-textarea`}
        name={`${meta.name}`}
        placeholder={`${meta.label}`} {...handler()}/>
    <div className="cf-form__hint">
      {touched
      && hasError("required")
      && `${meta.message} is required`}
    </div>
  </div>
)
