import React, { useCallback, useEffect, useState } from "react";
//import "../styles/components/modal.scss";

import ContactOpt from "./Contact/ContactOpt";

export default function Modal({item, onClick}) {

    return (
        <div>
            <div className="modal-backdrop" id="modal">
                <div className="modal-wrapper">
                    <div className="modal-content">
                        <ContactOpt/> 
                        
                    </div>
                    <div className="modal-close-btn" onClick={() => onClick(item.id)}>X</div>
                </div>
            </div>
        </div>
    )
}