import React from "react";

import Hero from "../components/Hero";
import Banner from "../components/Banner";
import Contact from "../components/Contact/Contact";
import Title from "../components/Title";


import LinkedinIcon from 'mdi-react/LinkedinIcon';
import Youtube from "mdi-react/YoutubeIcon";
import Instagram from "mdi-react/InstagramIcon";
import Facebook from "mdi-react/FacebookIcon";
import Laptop from "mdi-react/LaptopIcon";
import CellphoneArrowDown from "mdi-react/CellphoneArrowDownIcon";

import "../styles/layout/section.scss";
import "../styles/components/grid.scss";
import "../styles/layout/section-promo-secondary.scss"

import { MEDIA_PAGE, MEDIA_PAGE_SECTIONS } from "../constants/constants";

import "../styles/components/buttons/btn-white-o.scss";
import BarChart from "../components/Charts/BarChart";
import { Radar, Pie, Bar, HorizontalBar } from "react-chartjs-2";
//import { REACH_CARDS } from "../constants/constants";

export default function Media() {

    const REACH_CARDS = {
        services: [
            //Youtube   
            //color-primary-light = rgba(252, 159, 118, 1)
            //color-primary = rgba(231, 103, 48, .8)
            //color-secondary = rgba(56, 117, 152, 1)
            //color-secondary-dark = rgba(23,32,57,1)
            //random yell = rgb(255, 227, 0) #FFE300
            //bright af orange = #FF7800 rgb(255, 120, 0)
            {
                id: "media-btn",
                icon: <Youtube />,
                title: "Youtube",
                caption: "For 2021",
                link: "https://www.youtube.com/channel/UCmDrvxZ2ftlh8w7qBnNWhYA/?sub_confirmation=1",
                views: 11089,
                followers: 300,
                radarData: {
                    labels: [
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                        '55-64',
                        '65+'
                    ],
                    datasets: [{
                        label: 'Audience Age Reach (%)',
                        data: [28.2, 32.2, 17.7, 7.7, 5.8, 4.0],
                        fill: true,
                        backgroundColor: 'rgba(23,32,57,1)',
                        borderColor: 'rgba(56, 117, 152, 1)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }],
                },
                pieData: {
                    labels: [
                        'Women',
                        'Men',
                    ],
                    datasets: [{
                        label: 'Gender View %',
                        data: [10.7, 89.3],
                        backgroundColor: [
                            'rgb(255, 227, 0)',
                            'rgb(255, 120, 0)',
                        ],
                        hoverOffset: 4
                    }]
                },
                barData: {
                    labels: ['Views', 'Subs'],
                    datasets: [
                        {
                            label: 'People',
                            backgroundColor: '#EC932F',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [11089, 300]
                        }
                    ]
                },
                options: {
                    animations: {
                        tension: {
                            duration: 1000,
                            easing: 'linear',
                            from: 1,
                            to: 0,
                            loop: true
                        }
                    }
                }
            },
            //Instagram
            {
                id: "media-btn",
                icon: <Instagram />,
                title: "Instagram",
                caption: "Aug 30 - Nov 27",
                link: "https://www.instagram.com/comida_for_familias_inc/",
                views: 947,
                followers: 146,
                radarData: {
                    labels: [
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                    ],
                    datasets: [{
                        label: 'Audience Age Reach (%)',
                        data: [26, 47.1, 16.5, 5.1],
                        fill: true,
                        backgroundColor: 'rgba(23,32,57,1)',
                        borderColor: 'rgba(56, 117, 152, 1)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }],
                },
                pieData: {
                    labels: [
                        'Women',
                        'Men',
                    ],
                    datasets: [{
                        label: 'Audience Gender (%)',
                        data: [38.8, 61.1],
                        backgroundColor: [
                            'rgb(255, 227, 0)',
                            'rgb(255, 120, 0)',
                        ],
                        hoverOffset: 4
                    }]
                },
                barData: {
                    labels: ['Followers', 'Views'],
                    datasets: [
                        {
                            label: 'Views',
                            backgroundColor: '#EC932F',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [116, 831]
                        },
                    ]
                },
                options: {

                    animations: {
                        tension: {
                            duration: 1000,
                            easing: 'linear',
                            from: 1,
                            to: 0,
                            loop: true
                        }
                    }
                }
            },
            //Facebook
            {
                id: "media-btn",
                icon: <Facebook />,
                title: "Facebook",
                caption: "LifeTime Report ( OCT 28, 2018 - Nov 27, 2021 )",
                link: "https://www.facebook.com/comidaforfamilias",
                views: "N/A",
                Followers: 155,
                radarData: {
                    labels: [
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                        '55-64',
                        '65+'
                    ],
                    datasets: [
                        {
                            label: 'Women',
                            data: [11.4, 29.3, 6.1, 4.6, 0.7, 1.3],
                            fill: true,
                            backgroundColor: 'rgb(255, 227, 0)',
                            borderColor: 'rgba(255, 99, 132, 0.3)',
                            pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgb(255, 99, 132)'
                        },
                        {
                            label: 'Men',
                            data: [8.7, 29.9, 4.0, 2.0, 0.7, 1.3],
                            fill: true,
                            backgroundColor: 'rgba(255, 120, 0, 1)',
                            borderColor: 'rgba(23, 33, 57, 0.0)',
                            pointBackgroundColor: 'rgba(0, 99, 132, 0.5)',
                            pointBorderColor: '#fff',
                            pointHoverBackgroundColor: '#fff',
                            pointHoverBorderColor: 'rgb(255, 99, 132)'
                        }
                    ],

                },
                pieData: {
                    labels: [
                        'Women',
                        'Men',
                    ],
                    datasets: [{
                        label: 'Audience Gender (%)',
                        data: [53.4, 46.6],
                        backgroundColor: [
                            'rgb(255, 227, 0)',
                            'rgb(255, 120, 0)',
                        ],
                        hoverOffset: 4
                    }]
                },
                barData: {
                    labels: ['Youtube', 'Instagram', 'Linkedin', 'Facebook', 'Website'],
                    datasets: [
                        {
                            label: 'Views',
                            backgroundColor: '#EC932F',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [65, 59, 80, 81, 56]
                        },
                        {
                            label: 'Followers',
                            backgroundColor: 'rgba(255,99,132,0.2)',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [65, 59, 80, 81, 56, 55]
                        }
                    ]
                },
                options: {

                    animations: {
                        tension: {
                            duration: 1000,
                            easing: 'linear',
                            from: 1,
                            to: 0,
                            loop: true
                        }
                    }
                }
            },
            //Linkedin
            {
                id: "media-btn",
                icon: <LinkedinIcon />,
                title: "LinkedIn",
                caption: "Nov 1, 2020 - Oct 31, 2021 ",
                link: "https://www.linkedin.com/company/comidaforfamilias",
                views: "N/A",
                followers: 142,
                radarData: {
                    labels: [
                        'SF Bay',
                        'NYC',
                        'Philidelphia',
                        'Seattle',
                        'LA'

                    ],
                    datasets: [{
                        label: 'Page Visitors Location',
                        data: [323, 130, 88, 76, 64],
                        fill: true,
                        backgroundColor: 'rgba(23, 32, 57, 1)',
                        borderColor: 'rgba(255, 99, 132, 0.3)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }],
                },
                pieData: {
                    labels: [
                        'Engineering',
                        'Arts & Design',
                        'IT',
                        'Operations',
                        'Business Dev'
                    ],
                    datasets: [{
                        label: 'Visitors',
                        data: [348, 160, 117, 111, 108],
                        backgroundColor: [
                            'rgba(23,32,57,1)',
                            'rgb(255, 227, 0)',
                            'rgb(255, 120, 0)',
                            'rgb(252, 159, 118)',
                            'rgba(56, 117, 152, 1)'

                        ],
                        hoverOffset: 4
                    }]
                },
                barData: {
                    labels: ['Engineering', 'Arts and Design', 'IT', 'Operations', 'Business Dev'],
                    datasets: [
                        {
                            label: 'Visitors',
                            backgroundColor: '#EC932F',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [348, 160, 117, 111, 108]
                        },
                    ]
                },
                options: {

                    animations: {
                        tension: {
                            duration: 1000,
                            easing: 'linear',
                            from: 1,
                            to: 0,
                            loop: true
                        }
                    }
                }
            },
            {
                /**In the past 30 days.  */
                id: "media-btn",
                icon: <Laptop />,
                title: "Website",
                caption: "Oct 31, 2021 - Nov 29, 2021",
                link: "",
                views: "6700",
                followers: "N/A",
                radarData: {
                    labels: [
                        '18-24',
                        '25-34',
                        '35-44',
                        '45-54',
                        '55-64',
                        '65+'
                    ],
                    datasets: [{
                        label: 'Users Age (%)',
                        data: [24.32, 28.31, 20.09, 11.3, 9.36, 6.62],
                        fill: true,
                        backgroundColor: 'rgba(23, 32, 57, 1)',
                        borderColor: 'rgba(255, 99, 132, 0.0)',
                        pointBackgroundColor: 'rgba(255, 99, 132, 0.5)',
                        pointBorderColor: '#fff',
                        pointHoverBackgroundColor: '#fff',
                        pointHoverBorderColor: 'rgb(255, 99, 132)'
                    }],
                },
                pieData: {
                    labels: [
                        'Women',
                        'Men',
                    ],
                    datasets: [{
                        label: 'My First Dataset',
                        data: [565, 359],
                        backgroundColor: [
                            'rgb(255, 227, 0)',
                            'rgb(255, 120, 0)',
                        ],
                        hoverOffset: 4
                    }]
                },
                barData: {
                    labels: ['0'],
                    datasets: [
                        {
                            label: 'Views',
                            backgroundColor: '#EC932F',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [65, 59, 80, 81, 56, 55]
                        },
                        {
                            label: 'Followers',
                            backgroundColor: 'rgba(255,99,132,0.2)',
                            borderColor: 'rgba(255,99,132,1)',
                            borderWidth: 1,
                            hoverBackgroundColor: 'rgba(255,99,132,0.4)',
                            hoverBorderColor: 'rgba(255,99,132,1)',
                            data: [65, 59, 80, 81, 56, 55, 40]
                        }
                    ]
                },
                options: {
                    animations: {
                        tension: {
                            duration: 1000,
                            easing: 'linear',
                            from: 1,
                            to: 0,
                            loop: true
                        }
                    }
                }
            }
        ]
    };

    return (
        <React.Fragment>
            <Hero hero="cf-bg--media">
                <Banner title={MEDIA_PAGE.title}
                    subtitle={MEDIA_PAGE.subtitle}>
                    <div className="cf-hero__btn-group">
                        {MEDIA_PAGE.buttons.map((item, index) => {
                            return (
                                <a href={item.link}
                                    key={index}
                                    target='_blank'
                                    rel="noopener noreferrer"
                                    className='cf-btn--white-o'
                                    id="">
                                    <span>{item.text}</span>
                                </a>
                            )
                        })}
                    </div>
                </Banner>
            </Hero>
            <section className="cf-section__container">
                <section className="cf-section-promo-secondary">
                    <div className="cf-section-promo-secondary__guidelines">&nbsp;</div>
                    <div className="cf-section-promo__body">
                        <h3 className="cf-title">{MEDIA_PAGE_SECTIONS.guidelines.title}</h3>
                        <ul className="cf-section-promo__info">
                            <p className="cf-paragraph">{MEDIA_PAGE_SECTIONS.guidelines.paragraph}</p>
                            <a
                                target='_blank'
                                rel="noopener noreferrer"
                                className='cf-btn--primary'
                                href={MEDIA_PAGE_SECTIONS.guidelines.link}
                                id={MEDIA_PAGE_SECTIONS.guidelines.id}
                            >
                                {MEDIA_PAGE_SECTIONS.guidelines.buttontext}
                            </a>
                        </ul>
                    </div>
                </section>
            </section>

            <section className="cf-section__container">
                <section className="cf-section-promo-secondary-reverse">
                    <div className="cf-section-promo-secondary-reverse__radio">&nbsp;</div>
                    <div className="cf-section-promo-secondary-reverse__body">
                        <h3 className="cf-title">{MEDIA_PAGE_SECTIONS.radio.title}</h3>
                        <ul className="cf-section-promo-secondary-reverse__info">
                            <p className="cf-paragraph">{MEDIA_PAGE_SECTIONS.radio.paragraph}</p>
                            <a
                                target='_blank'
                                rel="noopener noreferrer"
                                className='cf-btn--primary'
                                href={MEDIA_PAGE_SECTIONS.radio.link}
                                id={MEDIA_PAGE_SECTIONS.radio.id}
                            >
                                {MEDIA_PAGE_SECTIONS.radio.buttontext}
                            </a>
                        </ul>
                    </div>
                </section>
                <iframe src="https://open.spotify.com/embed-podcast/episode/2DzHlXRJnymzbiw2kzOQDc"
                    width="100%"
                    height="auto"
                    frameborder="0"
                    allowtransparency="true"
                    allow="encrypted-media">
                </iframe>
            </section>

            <section className="cf-section__container">
                <section className="cf-section-promo-secondary">
                    <div className="cf-section-promo-secondary__hackathon">&nbsp;</div>
                    <div className="cf-section-promo__body">
                        <h3 className="cf-title">{MEDIA_PAGE_SECTIONS.hackathons.title}</h3>
                        <ul className="cf-section-promo__info">
                            <p className="cf-paragraph">{MEDIA_PAGE_SECTIONS.hackathons.paragraph}</p>
                            <a
                                target='_blank'
                                rel="noopener noreferrer"
                                className='cf-btn--primary'
                                href={MEDIA_PAGE_SECTIONS.hackathons.link}
                                id={MEDIA_PAGE_SECTIONS.hackathons.id}
                            >
                                {MEDIA_PAGE_SECTIONS.hackathons.buttontext}
                            </a>
                        </ul>
                    </div>
                </section>
            </section>

            <section className="cf-section--gray">
                <div className="cf-section__container" >
                    <Title sectionSize text="Our Reach" />
                    <BarChart />
                    <div >
                        {REACH_CARDS.services.map((item, index) => {
                            return (
                                <div className="cf-section__container" style={{ borderTop: "1px solid gray" }}
                                >
                                    <div className="cf-grid__col" >
                                        <a className="cf-grid__ico" href={item.link} id={item.id}>{item.icon}</a>
                                        <div className="cf-grid__title">{item.title}</div>
                                        <p>{item.caption}</p>
                                    </div>
                                    <ul className="cf-grid"
                                        key={index}>
                                        <li className="cf-grid__col">
                                            <h3>Views: {item.views}</h3>
                                            <h3>Followers: {item.followers}</h3>
                                        </li>
                                        <li className="cf-grid__col">
                                            <Pie data={item.pieData} />
                                        </li>
                                        <li className="cf-grid__col">
                                            <Bar data={item.radarData} />
                                        </li>
                                    </ul>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </section>
        </React.Fragment>
    );
}