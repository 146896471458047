import React from "react";

export default function Banner({children, title, subtitle, textstyle}) {
  return (
    <React.Fragment>
      <h1>{title}</h1>
      {subtitle && (
        <p>{subtitle}</p>
      )}
      {children}
    </React.Fragment>
  );
}

