import React, {Component} from "react";
import {NavLink} from "react-router-dom";

import {HashLink as Link} from 'react-router-hash-link';

import {ABOUT, NAVIGATION_MENU} from "../constants/constants"

import NavSidebar from "./NavSidebar";

import logo from "../images/logo.png";

import "../styles/layout/navbar.scss";

import ChevronDownIcon from "mdi-react/ChevronDownIcon";


class Navbar extends Component {

  constructor(props) {
    super(props);

    const isOpenDropdown = {};

    this.state = {
      isOpenDropdown,
    }
  }

  /**
   * Toggle Dropdown by click.
   * @param index - of dropdown.
   */
  toggleDropdown = (index) => {
    const {
      state: {isOpenDropdown},
    } = this;
    const isOpen = !!isOpenDropdown[index];
    this.setState({isOpenDropdown: {[index]: !isOpen}});
  }

  render() {
    /**
     * Dropdown Menu.
     * @param {array} data - array of dropdown-menu items.
     * @param {number} index - of dropdown-menu item.
     * @returns {null|*} - items for dropdown-menu or null.
     */
    const dropdownMenu = (data, index) => {
      if (this.state.isOpenDropdown[index]) {
        return (
          <div className="cf-nav__dropdown__menu">
            {data.dropdown.map(item => (
              <NavLink to={item.link}
                       key={item.id}
                       activeClassName="is-active"
                       className="cf-nav__dropdown__link">{item.text}</NavLink>
            ))}
          </div>
        )
      } else {
        return null
      }
    }

    /**
     * Dropdown.
     * @param {array} data - array of dropdown items.
     * @param {number} index - of dropdown items.
     * @returns {null|*} - dropdown items or null.
     */
    const dropdown = (data, index) => {

      if (data) {
        const isOpenClass = this.state.isOpenDropdown[index] ? 'is-open' : '';

        return (
          <div className={`cf-nav__dropdown ${isOpenClass}`} key={data.id}
               onClick={() => this.toggleDropdown(index)}>
            <span>{data.text}<ChevronDownIcon className="cf-nav__dropdown-ico"/></span>
            {dropdownMenu(data, index)}
          </div>
        )
      } else {
        return null
      }
    };

    return (
      <nav aria-label="Site Navigation"
           className="cf-nav">
        <Link className="cf-nav__logo"
              to="/">
          <img src={logo} alt="Logo"/>
          <span>{ABOUT.name}</span>
        </Link>

        <div className="cf-nav__menu">
          <div className="cf-menu-desktop">
            {NAVIGATION_MENU.map((item, index) => {
              if (item.dropdown) {
                return (dropdown(item, index))
              } else {
                return (
                  <NavLink to={item.link}
                           exact
                           key={item.id}
                           activeClassName="is-active"
                           className="cf-nav__link">{item.text}</NavLink>
                )
              }
            })}
          </div>

          <NavSidebar/>
          <Link to="/#contacts">Contacts</Link>
          <Link to="/Donate"
                className="cf-nav__btn">Donate</Link>
        </div>
      </nav>
    );
  }
}

export default Navbar
