import React from "react";

import Hero from "../../components/Hero";
import ImageSlider from "../../components/Carousel"; //Carousel
import Banner from "../../components/Banner";
import Impact from "../../components/Impact";
import ToHelp from "./ToHelp";
import Current from "./Current";
import Contact from "../../components/Contact/Contact";
import PostList from "./PostList";
import Brands from "../../components/Brands"; 

import {HOME_PAGE} from "../../constants/constants";

import "../../styles/components/buttons/btn-white-o.scss";

export default function Home() {

  const SliderData = [
    {
        image: "https://images.unsplash.com/photo-1505852679233-d9fd70aff56d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=750&q=80"
    },
    {
        image: "https://images.unsplash.com/photo-1551091708-fda32ed3178c?ixid=MnwxMjA3fDB8MHxzZWFyY2h8MTF8fGxhbmRzY2FwZXN8ZW58MHx8MHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=500&q=60"
    },
    {
        image: "https://images.unsplash.com/photo-1603990645242-0226d549dd31?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=667&q=80"
    }
]

  return (
    <React.Fragment>
      <ImageSlider slides={SliderData}/> {/*Go to carousel to change the slides*/}
      <Impact/>
      <ToHelp/>
      <Current/>
      <PostList/> 
      <Brands/>
      <Contact/>
    </React.Fragment>
  );
}





