import {DOMAIN_URL} from "../constants/constants";
import axios from "axios";


export const getInternships = async () => {
  const res = await axios.get(DOMAIN_URL + "/internships", {
    headers: { "Content-Type": "application/json;charset=UTF-8" },
  });
  const data = res.data.map((item) => {
    return {
      id: item._id,
      title: item.title,
      description: item.description,
      applyLink: item.applyLink,
      role: item.list,
    };
  });

  return data || [];
};
