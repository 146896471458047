import React from "react"
import { FormGenerator } from "react-reactive-form";

import Title from "../Title";

import "../../styles/components/contact.scss";
import "../../styles/layout/section.scss";
import "../../styles/components/link-primary.scss";

import "../../styles/components/form.scss";

import { CONTACTS_CONTENT, F1OPT_PAGE } from "../../constants/constants";

import { postContact } from "../../services/contactService";
import { fieldConfigOpt } from "./fieldConfigOpt";

class ContactOpt extends React.Component {
  handleReset = () => {
    this.contactForm.reset();
  }

  handleSubmit = (e) => {
    postContact(this.contactForm.value)
      .then(() => alert("Your form has been submitted. Click ok and then exit our of modal."))
      .catch(() => alert("Message failed to send."))
    e.preventDefault();
  }

  setForm = (form) => {
    this.contactForm = form;
    this.contactForm.meta = {
      handleReset: this.handleReset
    }
  }

  render() {
    return (
      <section className="cf-section cf-section--gray"
        id="contacts">
        <div className="cf-section__container">
          <Title text={F1OPT_PAGE.priceCards[0].modalTitle} sectionSize>&nbsp;</Title>
          <div className="cf-contact">
            <div className="cf-contact__col">
              <p>{F1OPT_PAGE.priceCards[0].modalInfo}</p>
              <address>
                <strong>Corporate Correspondence <br></br>(For Rapid Response) </strong>
                <p>
                  {CONTACTS_CONTENT.address}<br />
                  Phone:&nbsp;<a className="cf-link--primary"
                    href={`tel:${CONTACTS_CONTENT.phone}`}>{CONTACTS_CONTENT.phone}</a><br />
                  E-mail:&nbsp;<a className="cf-link--primary"
                    href={`mailto:${CONTACTS_CONTENT.email}`}>{CONTACTS_CONTENT.email}</a>
                </p>
              </address>

            </div>
            <div className="cf-contact__col">
              <form className="cf-form" 
                onSubmit={this.handleSubmit}>
                <input type="hidden" name="oid" value="00D5w0000078woY" />
                <input type="hidden" name="debug" value={0} />
                <input type="hidden" name="debugEmail"
                  value="info@comidaforfamilias.com" />
                <input type="hidden" name="retURL" value ="https://comidaforfamilias.com/"/>
                <FormGenerator onMount={this.setForm}
                  fieldConfig={fieldConfigOpt} />
              </form>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default ContactOpt;