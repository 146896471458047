import { DOMAIN_URL } from "../constants/constants";
import axios from "axios";


export const postContact = async (params) => {
  await axios.post(DOMAIN_URL + "/send", params);
  //      var data =  Object.keys(params).map(function(k) {
  //       return encodeURIComponent(k) + '=' + encodeURIComponent(params[k])
  //     }).join('&')
  //  await axios.post('https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8', data);
};
