import React from "react";

import {
  NEWS_EVENTS,
  NEWS_EVENTS_SWITCH_ALL,
  NEWS_EVENTS_SWITCH_TECH,
  NEWS_EVENTS_SWITCH_TOP,
  NEWS_TYPE
} from "../../constants/constants";

import { getNews } from "../../services/newsService";
import Loading from "../../components/Loading";
import NoData from "../../components/NoData";
import Hero from "../../components/Hero";
import Banner from "../../components/Banner";
import Breadcrumbs from "../../components/Breadcrumbs";
import Switcher from "../../components/Switcher";
import NewsEventsCard from "./NewsEventsCard";
import Pagination from '@material-ui/lab/Pagination';

const PER_PAGE = 5;
let TOTAL_COUNT = 0;

class News extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],
      activePage: 1,
      loadingStatus: true,
      title: NEWS_TYPE,
      switcher: NEWS_EVENTS.switcherList.news,
      breadcrumbs: NEWS_EVENTS.breadcrumbs.news,
      filterBy: NEWS_EVENTS.switcherList.news[0].name,
    };
  }

  handlePageChange(event, pageNumber) {
    console.log(`active page is ${pageNumber}`);
    this.setState({ activePage: pageNumber });
  }

  componentDidMount() {
    getNews()
      .then((res) => {
        const data = res.sort((a, b) => b.date - a.date);

        TOTAL_COUNT = data.length / PER_PAGE;
        console.log(TOTAL_COUNT);

        this.setState({
          data,
          _data: data,
          loadingStatus: false,
        });
      })
      .catch((e) => console.log(e))
  }

  /**
   * Update this.state.filterBy - with selected value for filter list of News.
   * @param {string} value - of selected Tab from Switcher Component.
   */
  updateData = (value) => {
    const valueUpper = value.toUpperCase();
    const dataInitial = this.state._data;

    const data = valueUpper === NEWS_EVENTS_SWITCH_ALL.toUpperCase()
      ? dataInitial
      : dataInitial.filter(item => item.theme.toUpperCase() === valueUpper);

    this.setState({
      filterBy: value,
      data
    })
  }

  /**
   * Check - has News list or empty.
   * @returns {*}
   */
  content = () => {
    const pageLowerIndex = (this.state.activePage - 1) * PER_PAGE;
    const pageUpperIndex = pageLowerIndex + PER_PAGE;
    const list = [];
    this.state.data.forEach((item, index) => {
      if (pageLowerIndex <= index && index < pageUpperIndex) {
        list.push(
          < NewsEventsCard key={index}
            badgeText={item.theme}
            badgeColor={item.theme === NEWS_EVENTS_SWITCH_TOP ? "primary" : item.theme === NEWS_EVENTS_SWITCH_TECH ? "secondary" : null}
            item={item} />
        )
      }
    });


    if (this.state.data?.length > 0) {
      if (this.state.loadingStatus) {
        return <Loading />
      } else {
        console.log('list', list);
        return list;
      }
    } else {
      return <NoData text={`There is no ${this.state.title} yet...`} />
    }
  }

  render() {
    return (
      <React.Fragment>
        <Hero hero="cf-bg--events">
          <Banner title={this.state.title} />
        </Hero>
        <section className="cf-section">
          <div className="cf-section__container cf-padding-t-base">
            <Breadcrumbs data={this.state.breadcrumbs} />
            <Switcher data={this.state.switcher}
              defaultValue={this.state.filterBy}
              updateData={this.updateData} />
            <div className="cf-card-list cf-card-preview--small">
              {this.content()}
            </div>
            {/* <div style={{display: 'flex', justifyContent:'center', alignItems:'center'}}>
            <Pagination count={TOTAL_COUNT} page={this.state.activePage} onChange={this.handlePageChange.bind(this)} />
            </div> */}
          </div>
        </section>
      </React.Fragment>
    )
  }
}

export default News;
