import React from "react";
import Video from "../videos/vegetables.mp4"

import "../styles/pages/quisines.scss";
import "../styles/components/hero/hero.scss";
import "../styles/components/buttons/btn-white-o.scss";
//import BannerVideo from "../components/BannerVideo";
import Banner from "../components/Banner";
import Hero from "../components/Hero";

import { RELAXASSISTANT } from "../constants/constants";
import AppleDownload from "../icons/appledownload.svg";
import googleplaydownload from "../icons/googleplaydownload.png";
import { text } from "body-parser";


export default function RelaxAssistant() {
    return (

        <Hero hero="cf-bg--relaxassistant" text="center">

            <Banner title={RELAXASSISTANT.title}
                subtitle={RELAXASSISTANT.subtitles}
                />

                {/* <a className="cf-hero__link"
                    href={RELAXASSISTANT.note.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    >{RELAXASSISTANT.note.text}</a> */}

                <div className="cf-hero__btn-group">
                    {RELAXASSISTANT.buttons.map((item, index) => {
                        return (
                            <a href={item.link}
                                key={index}
                                target='_blank'
                                rel="noopener noreferrer"
                                aria-label={`To open ${item.ariaLabel}`}
                                className="cf-btn--primary"
                                id="relax-btn">
                                    {item.text}
                            </a>
                        )
                    })}
                </div>
        </Hero>
    )


    /*
    return (
        <header className="header">
            <video className='video-background' autoPlay loop muted>
                    <source src={Video} type='video/mp4' />
            </video>
          <div className="cf-hero__info">
          </div>
        </header>
      );
/*
    /*
    return (
        <div>
            <section class="header">
                <video className='video-background' autoPlay loop muted>
                    <source src={Video} type='video/mp4' />
                </video>
                <div class="welcome">
                    <h1>Explore Texas</h1>
                    <a href="#" class="btn btn-howdy">Howdy</a>
                    <a href="#" class="btn btn-events">Events</a>
                </div>
            </section>
        </div>
    )
    */
}
