import React from "react";

import Hero from "../components/Hero";
import Title from "../components/Title";

import MouseIcon from "mdi-react/MouseIcon";
import DollarIcon from "mdi-react/DollarIcon";
import WrenchIcon from "mdi-react/WrenchIcon";
import HeartIcon from "mdi-react/HeartIcon";

import {
  CODES,
  DONATE_DEV,
  DONATE_FINANCING,
  DONATE_PAGE, DONATE_PROJECT
} from "../constants/constants";

import '../styles/pages/donate.scss'

export default function Home() {
  const checkIcon = (value) => {
    switch (value) {
      case DONATE_DEV:
        return (<MouseIcon/>)
      case DONATE_PROJECT:
        return (<WrenchIcon/>)
      case DONATE_FINANCING:
        return (<DollarIcon/>)
      default:
        return (<HeartIcon/>)
    }
  }

  return (
    <React.Fragment>
      <Hero hero="cf-bg--donate">
        <Title text='We Accept Donations'
               bigSize
               primaryText='to keep our organization running'/>
        <p>{DONATE_PAGE.info}</p>
        <a href={DONATE_PAGE.btnDonateLink}
           target='_blank'
           rel="noopener noreferrer"
           className='cf-btn--white-o'
           id="donate-btn">
          <span>{DONATE_PAGE.btnDonateText}</span>
        </a>
        <h4 className="cf-donate__title">{CODES.title}</h4>
        <ul className="cf-donate__list">
          {CODES.list.map((item, index) => {
            return (
              <li key={index}>
                <div className="cf-ico">{item.code}</div>
                <div>{item.text}</div>
              </li>
            )
          })}
        </ul>
      </Hero>
      <section className="cf-section">
        <div className="cf-section__container">
          <Title text={DONATE_PAGE.donationsFlow.title}
                 sectionSize/>
          <ul className="cf-grid">
            {DONATE_PAGE.donationsFlow.list.map((item, index) => {
              return (
                <li className="cf-grid__col"
                    key={item.id}>
                  <div className="cf-grid__ico">{checkIcon(item.ico)}</div>
                  <div>{item.text}</div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
      <section className="cf-section--gray">
        <div className="cf-section__container cf-center">
          <Title text='We want to continue caring for people'
                 sectionSize
                 primaryText="with you"/>
          <Title text={DONATE_PAGE.notice.title}/>
          <p>{DONATE_PAGE.notice.info}</p>
          <a href={DONATE_PAGE.btnDonateLink}
             target='_blank'
             rel="noopener noreferrer"
             className='cf-btn--primary'
             id="donate-btn">
            <span>{DONATE_PAGE.btnDonateText}</span>
          </a>
        </div>
      </section>
      <section className="cf-section">
        <div className="cf-section__container">
          <p className="cf-donate__info">{DONATE_PAGE.thanks.info}</p>
          <Title text='many thanks'
                 sectionSize
                 primaryText='to your contributions toward a greater cause!'/>
          <ul className="cf-donate__list">
            {DONATE_PAGE.thanks.list.map((item, index) => {
              return (
                <li key={index}>{item.name}</li>
              )
            })}
          </ul>
        </div>
      </section>
    </React.Fragment>
  );

}
